import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text } from "@chakra-ui/react";
import axios from "axios";
import URL from "constants/url";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ModalDeleteAccount = ({ isOpen, handleClose }) => {

  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [decodedToken, setDecodedToken] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      history.push("/auth/iniciar-sesion");
    } else {
      (async () => {
        setDecodedToken(await jwtDecode(token));
      })();

      if (decodedToken && decodedToken?.exp * 1000 < Date.now()) {
        history.push("/auth/iniciar-sesion");
      }
    }
  }, [history]);

  const deleteAccount = async () => {
    try {
      setLoading(true)
      const account = await axios.delete(`${URL}/api/auth/delete_account/${decodedToken.userId}`)
      if(account.status === 200){
        setLoading(false)
        history.push("/")
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <div>
      <Modal isOpen={isOpen} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="20px">
          <ModalHeader>¿Estas seguro de querer borrar tu cuenta?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Vas a perder todos tus datos guardados. Esta acción es irreversible.</Text>
          </ModalBody>
          <ModalFooter>
            <Flex gap="10px" width="100%">
                <Button
                onClick={handleClose}
                variant="outline"
                style={{
                    color: "#4319FF",
                    width: "50%",
                    fontSize: "14px"
                }}
                >
                    Cancelar
                </Button>
                <Button
                onClick={deleteAccount}
                style={{
                    backgroundColor: "red",
                    color: "white",
                    width: "50%",
                    fontSize: "14px"
                }}
                >
                Borrar cuenta
                </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ModalDeleteAccount;
