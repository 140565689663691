import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

// Chakra imports
import { Box, Flex, Grid, useColorModeValue } from "@chakra-ui/react";

// Custom components
import TablaActivos from "views/admin/gestionactivos/components/TableTopCreators";
import Card from "components/card/Card.js";

// Assets
import tableDataTopCreators from "views/admin/gestionactivos/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/gestionactivos/variables/tableColumnsTopCreators";

export default function GestionActivos() {
  const history = useHistory();
  const [decodedToken, setDecodedToken] = useState(null);

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      history.push("/auth/iniciar-sesion");
    } else {
      (async () => {
        setDecodedToken(await jwtDecode(token));
      })();

      if (decodedToken && decodedToken?.exp * 1000 < Date.now()) {
        history.push("/auth/iniciar-sesion");
      }
    }
  }, [history]);

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px", sm: "130px" }}>
      {/* Main Fields */}
      <Grid
        mb="20px"
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block" }}
      >
        <Flex
          flexDirection="column"
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
        >
          <Card px="0px" mb="20px">
            {decodedToken && (
              <TablaActivos
                tableData={tableDataTopCreators}
                columnsData={tableColumnsTopCreators}
                decodedToken={decodedToken}
              />
            )}
          </Card>
        </Flex>
      </Grid>
      {/* Delete Product */}
    </Box>
  );
}
