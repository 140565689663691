import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Stack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  // extendTheme,
  useColorModeValue,
  ChakraProvider,
  Icon,
  Spinner,
  useToast,
  Select,
  Box, // Importa el ícono de CheckCircle
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { MdQueryStats, Md123 } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import axios from "axios";
import URL from "constants/url";
import { useCurrency } from "contexts/CurrencyContext";

// const theme = extendTheme({
//   colors: {
//     brand: {
//       500: "#4319FF",
//       600: "#82FFC9",
//     },
//   },
// });

function ModalQuitarActivos({ isOpen, onClose, decodedToken, actualizar }) {
  const [isLoading, setIsLoading] = useState(false);
  const [userActives, setUserActives] = useState(null);
  const toast = useToast();

  const { currency } = useCurrency()
  const tipoValor = currency === "ARS" ? "valor_pesos" : "valor"
  const formatter = new Intl.NumberFormat("es-ES", {});

  const [ticker, setTicker] = useState({
    tickerId: "",
    amount: "",
  });

  const isTickerComplete = ticker.tickerId.trim() !== "";
  const isCantidadComplete = ticker.amount.trim() !== "";

  const handleClose = () => {
    setTicker({
      tickerId: "",
      amount: "",
    });

    onClose();
  };

  useEffect(() => {
    if (Object.keys(decodedToken).length !== 0) {
      (async () => {

        const actives = await axios.get(
          `${URL}/api/actives/${decodedToken.userId}`
        );
        setUserActives(actives.data.Actives);
      })();
    }
  }, [decodedToken]);

  const handleTicker = (e) => {
    setTicker({ ...ticker, [e.target.name]: e.target.value });
  };

  const handleQuitarActivo = async () => {
    setIsLoading(true);

    const body = { ...ticker, ...decodedToken };

    await axios.put(`${URL}/api/actives/${decodedToken.userId}`, body);

    // Simula una operación asincrónica
    setTimeout(() => {
      setIsLoading(false);

      toast({
        position: "bottom",
        duration: 3000,
        isClosable: true,
        render: () => (
          <Box
            color="white"
            p={3}
            bg="#38B6FF"
            borderRadius="md"
            fontWeight="500"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            animate={{ opacity: [0, 1], translateY: [10, 0] }}
          >
            Activo Removido de tu balance!
            <Icon
              as={CheckCircleIcon}
              boxSize={5}
              motion={{ type: "spring", stiffness: 400, damping: 25 }}
            />
          </Box>
        ),
      });
      actualizar()
      handleClose();
    }, 2000); // Ejemplo: espera 2 segundos antes de restablecer el estado
  };

  return (
    <ChakraProvider>
      <Modal onClose={handleClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="20px">
          <ModalHeader>Remover Activo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              {/* <InputGroup>
                <InputLeftElement fontSize="20px">
                  <MdQueryStats />
                </InputLeftElement>
                <Input
                  type=""
                  placeholder="Ticker"
                  value={ticker}
                  onChange={(e) => setTicker(e.target.value)}
                  focusBorderColor={useColorModeValue("#422AFB", "white")}
                />
                {isTickerComplete && (
                  <InputRightElement>
                    <Icon as={FaCheck} color="brand.600" />
                  </InputRightElement>
                )}
              </InputGroup> */}

              <InputGroup>
                {/* <InputLeftElement fontSize="20px">
                  <MdQueryStats />
                </InputLeftElement> */}
                <Select
      placeholder="Ticker"
      name="tickerId"
      multiple={false}
      onChange={handleTicker}
      borderColor={useColorModeValue("#422AFB", "white")}
      focusBorderColor={useColorModeValue("#422AFB", "white")}
      borderRadius="md"
    >
      {userActives &&
        userActives.map((act) => (
          act.User_Active.amount > 0 ? (
            <option key={act.id} value={act.id}>
              {act.ticker}
            </option>
          ) : null
        ))}
    </Select>
              </InputGroup>
              <InputGroup>
                <InputLeftElement fontSize="30px">
                  <Md123 />
                </InputLeftElement>
                <Input
                  name="amount"
                  type="number"
                  placeholder="Cantidad"
                  value={ticker.amount}
                  onChange={handleTicker}
                  focusBorderColor={useColorModeValue("#422AFB", "white")}
                />
                {isCantidadComplete && (
                  <InputRightElement>
                    <Icon as={FaCheck} color="brand.600" />
                  </InputRightElement>
                )}
              </InputGroup>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleQuitarActivo}
              isDisabled={!isTickerComplete || !isCantidadComplete || isLoading}
              style={{
                backgroundColor: "#4319FF",
                color: "white",
              }}
            >
              {isLoading ? (
                <Spinner size="sm" color="white" />
              ) : (
                "Quitar Activo de mi Cartera"
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default ModalQuitarActivos;
