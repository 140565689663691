import React, { useEffect, useMemo, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Heading,
  Icon,
  IconButton,
  Image,
  Tag,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Select,
} from "@chakra-ui/react";
import ModalSumarActivos from "./ModalSumarActivos";
import ModalQuitarActivos from "./ModalQuitarActivos";
import axios from "axios";
import { MdModeEdit, MdDeblur, MdDelete, MdArrowDropUp, MdAdd } from "react-icons/md";
import ModalEditarActivo from "./ModalEditarActivo";
import ModalBorrarActivo from "./ModalBorrarActivo";
import DEFAULT_ICON from "../../../../assets/img/default.png";
import { FaCoins } from "react-icons/fa";
import URL from "constants/url";
import { useCurrency } from "contexts/CurrencyContext";
import { TIPO_ICONOS } from "constants/iconos";
import dayjs from "dayjs";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function TopCreatorTable(props) {
  const { columnsData, decodedToken } = props;
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const columns = useMemo(() => columnsData, [columnsData]);
  const { currency } = useCurrency();
  const tipoValor = currency === "ARS" ? "valor_pesos" : "valor";
  const tipoValorAnterior =
    currency === "ARS" ? "valor_anterior_pesos" : "valor_anterior";
  const [loading, setLoading] = useState(true);

  const [tickerSelected, setTickerSelected] = useState(null);
  const [dataUser, setDataUser] = useState([]);
  const [filterIdTipo, setFilterIdTipo] = useState("all");

  const [actualizarEstado, setActualizarEstado] = useState(false);
  const formatter = new Intl.NumberFormat("es-ES", { maximumSignificantDigits: 8 });
  const [lastUpdate, setLastUpdate] = useState(null);

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get("activo");  
  console.log(param)

  const TIPOS_ACTIVOS_IDS = {
    criptomonedas: 1,
    cedears: 2,
    accionesargentinas: 3
  }

  useEffect(() => {
    if(param){
      setFilterIdTipo(TIPOS_ACTIVOS_IDS[param])
    }
  }, [param])

  const actualizar = () => {
    setActualizarEstado(!actualizarEstado);
  };

  const getUserActives = async () => {
    setLoading(true);
    try {
      const data = await axios.get(`${URL}/api/actives/${decodedToken.userId}`);
      const lastUpdate = await axios.get(`${URL}/api/actives/last_update`);
      setDataUser(data.data);
      setLastUpdate(lastUpdate.data.lastUpdate);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserActives();
  }, []);

  const handleFilterChange = (e) => {
    setFilterIdTipo(e.target.value);
  };

  const data = useMemo(
    () =>
      dataUser && dataUser.Actives
        ? dataUser.Actives.map((item) => ({
            ...item,
            total: item.valor * parseFloat(item.User_Active.amount),
          }))
        : [],
    [dataUser]
  );

  const filteredData = useMemo(() => {
    if (filterIdTipo === "all") {
      return data;
    }
    console.log(filterIdTipo)
    return data.filter((item) => item.id_tipo === parseInt(filterIdTipo));
  }, [data, filterIdTipo]);

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });
  const [sorting, setSorting] = useState([]);

  const tableColumnsMobile = [
    {
      header: "Ticker",
      cell: ({ cell }) => {
        console.log(cell);
        return (
          <Flex align="center">
            <Box position="relative">
              <Avatar
                src={
                  cell.row.original.logo ? cell.row.original.logo : DEFAULT_ICON
                }
                w={isMobile ? "30px" : "40px"}
                h={isMobile ? "30px" : "40px"}
                me="8px"
                backgroundColor="white"
                borderWidth="1px"
                borderColor="#eaeaea"
              />
              <Avatar
                src={TIPO_ICONOS[cell.row.original.id_tipo]}
                position="absolute"
                w="15px"
                h="15px"
                top="0"
                right="5px"
              />
            </Box>
            <Flex direction="column">
              <Text
                color={textColor}
                fontWeight="600"
                fontSize={{ sm: "12px", lg: "12px" }}
              >
                {cell.row.original.ticker}
              </Text>
              <Text
fontSize={{ sm: "12px", lg: "12px" }}
          >
{currency === "ARS"
              ? `$ ${formatter.format(cell.row.original[tipoValor])}`
              : `US$ ${formatter.format(
                  cell.row.original[tipoValor]
                )}`}
          </Text>
            </Flex>
          </Flex>
        );
      },
    },
    // {
    //   header: "Cantidad",
    //   accessorFn: (row) => row.User_Active.amount,
    // },
    {
      header: "Total",
      cell: ({ cell }) => {
        const totalValue =
          cell.row.original[tipoValor] * cell.row.original.User_Active.amount;
        return (
          <Flex direction="column">
          <Text fontSize={{ sm: "12px", lg: "12px" }} fontWeight="600">
            {currency === "ARS"
              ? `$ ${formatter.format(totalValue)}`
              : `US$ ${formatter.format(totalValue.toFixed(2))}`}
          </Text>
          <Text
fontSize={{ sm: "12px", lg: "12px" }}
          >
            Cantidad: {cell.row.original.User_Active.amount}
          </Text>
        </Flex>

        );
      },
    },
    {
      header: "Acciones",
      cell: ({ cell }) => {
        return (
          <Flex gap="10px">
            <IconButton
              size="md"
              color={textColorSecondary}
              onClick={() => handleEditModal(cell.row.original)}
            >
              <MdModeEdit />
            </IconButton>
            <IconButton
              size="md"
              color={textColorSecondary}
              onClick={() => handleDeleteModal(cell.row.original)}
            >
              <MdDelete />
            </IconButton>
          </Flex>
        );
      },
    },
  ];

  const tableColumns = [
    {
      header: "Ticker",
      cell: ({ cell }) => {
        console.log(cell);
        return (
          <Flex align="center">
            <Box position="relative">
              <Image
                src={
                  cell.row.original.logo ? cell.row.original.logo : DEFAULT_ICON
                }
                w={isMobile ? "30px" : "40px"}
                h={isMobile ? "30px" : "40px"}
                me="8px"
                backgroundColor="white"
                borderWidth="1px"
                borderColor="#eaeaea"
                borderRadius="full"
              />
              <Image
                src={TIPO_ICONOS[cell.row.original.id_tipo]}
                position="absolute"
                w="15px"
                h="15px"
                top="0"
                right="5px"
                borderRadius="full"
              />
            </Box>
            <Flex direction="column">
              <Text
                color={textColor}
                fontWeight="600"
                fontSize={{ sm: "12px", lg: "12px" }}
              >
                {cell.row.original.ticker}
              </Text>
            </Flex>
          </Flex>
        );
      },
      accessorFn: (row) => row.ticker,
      sortingFn: "text",
    },
    {
      header: "Tipo",
      accessorFn: (row) => row.Type.type,
    },
    {
      header: "Precio Anterior",
      cell: ({ cell }) => {
        return (
          <span>
            {currency === "ARS"
              ? `$ ${formatter.format(cell.row.original[tipoValorAnterior])}`
              : `US$ ${formatter.format(
                  cell.row.original[tipoValorAnterior]
                )}`}
          </span>
        );
      },
      accessorFn: (row) => row[tipoValorAnterior],
    },
    {
      header: "Precio Actual",
      cell: ({ cell }) => {
        return (
          <span>
            {currency === "ARS"
              ? `$ ${formatter.format(cell.row.original[tipoValor])}`
              : `US$ ${formatter.format(
                  cell.row.original[tipoValor]
                )}`}
          </span>
        );
      },
      accessorFn: (row) => row[tipoValor],
    },
    {
      header: "Variación 24hs",
      cell: ({ cell }) => {
        return (
          <Text
            fontWeight={600}
            display="flex"
            alignItems="center"
            position={"relative"}
            color={
              parseFloat(cell.row.original.variacion_porcentaje) < 0
                ? "red"
                : "#1eb87c"
            }
          >
            <>
            <Icon
              color={
                parseFloat(cell.row.original.variacion_porcentaje) < 0
                  ? "red"
                  : "#1eb87c"
              }
              fontSize={"22px"}
              position={"relative"}
            >
              {parseFloat(cell.row.original.variacion_porcentaje) < 0 ? (
                <BsArrowDownShort />
              ) : (
                <BsArrowUpShort />
              )}
            </Icon>
            {parseFloat(cell.row.original.variacion_porcentaje).toFixed(2)}%
            </>
            
          </Text>
        );
      },
      accessorFn: (row) => row.variacion_porcentaje,
    },
    {
      header: "Cantidad",
      accessorFn: (row) => row.User_Active.amount,
    },
    {
      header: "Total",
      accesorKey: 'total',
      accessorFn: (row) => row.total,
      cell: ({ cell }) => {
        const totalValue =
          cell.row.original[tipoValor] * cell.row.original.User_Active.amount;
        return (
          <span>
            {currency === "ARS"
              ? `$ ${formatter.format(totalValue)}`
              : `US$ ${formatter.format(totalValue.toFixed(2))}`}
          </span>
        );
      },
      sortingFn: (rowA, rowB, columnId) => {
        return (rowA.original[tipoValor] * rowA.original.User_Active.amount) - (rowB.original[tipoValor] * rowB.original.User_Active.amount)
      },
    },
    {
      header: "Acciones",
      cell: ({ cell }) => {
        return (
          <Flex gap="10px">
            <IconButton
              size="md"
              color={textColorSecondary}
              onClick={() => handleEditModal(cell.row.original)}
            >
              <MdModeEdit />
            </IconButton>
            <IconButton
              size="md"
              color={textColorSecondary}
              onClick={() => handleDeleteModal(cell.row.original)}
            >
              <MdDelete />
            </IconButton>
          </Flex>
        );
      },
    },
  ];

  const tableInstance = useReactTable({
    columns: isMobile ? tableColumnsMobile : tableColumns,
    data: filteredData,
    state: {
      pagination,
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuitarModalOpen, setIsQuitarModalOpen] = useState(false);
  const [isEditarModalOpen, setIsEditarModalOpen] = useState(false);
  const [isBorrarModalOpen, setIsBorrarModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openQuitarModal = () => {
    setIsQuitarModalOpen(true);
  };

  const closeQuitarModal = () => {
    setIsQuitarModalOpen(false);
  };

  const closeEditarModal = () => {
    setIsEditarModalOpen(false);
  };

  const closeBorrarModal = () => {
    setIsBorrarModalOpen(false);
  };

  const handleEditModal = (ticker) => {
    setTickerSelected(ticker);
    setIsEditarModalOpen(true);
  };

  const handleDeleteModal = (ticker) => {
    setTickerSelected(ticker);
    setIsBorrarModalOpen(true);
  };

  const textColor = "navy.700";
  const textColorSecondary = "#A3AED0";

  return (
    <>
      <Flex
        direction={isMobile ? "column" : "row"}
        align={{ sm: "flex-start", lg: "center" }}
        justify={isMobile ? "space-between" : "center"}
        w="100%"
        px="22px"
        pb="20px"
        p={isMobile ? "8px" : ""}
        mb="10px"
        boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
        mt="30px"
      >
        <Flex
          align="center"
          w={isMobile ? "100%" : "auto"}
          mt={isMobile ? "-55px" : "0"}
        >
          <Text
            color={textColor}
            fontSize="xl"
            fontWeight="600"
            mb={isMobile ? "0px" : "0"}
          >
            Mis activos
          </Text>
          <Flex ml={isMobile ? "auto" : "20px"}>
            {!isMobile ?
            <Button variant="action" backgroundColor={"#4319FF"} fontSize={"sm"} color="#fff" _hover={{ opacity: "0.8"}} onClick={openModal}>
              Agregar Activo
            </Button>
            :
            <IconButton
              size="md"
              color={"#fff"}
              onClick={openModal}
              backgroundColor={"#4319FF"}
            >
              <MdAdd />
            </IconButton>
            }
          </Flex>
        </Flex>

        {isMobile && (
          <Flex align="center" mt={isMobile ? "10px" : "0"} w="100%" justify="space-between" gap="50px">
                        {lastUpdate ? (
                <Tag variant='subtle' color="#4319ff" backgroundColor="#efecff" fontSize="14px">
                Ult. Act. {lastUpdate}hs
                </Tag>
            ) : null}
            <Select
              value={filterIdTipo}
              onChange={handleFilterChange}
              fontSize="sm"
              color="gray.500"
              flex="1"
            >
              <option value="all">Todos</option>
              <option value="1">Criptomonedas</option>
              <option value="2">Cedears</option>
              <option value="3">Acciones</option>
            </Select>
          </Flex>
        )}

        {!isMobile && (
          <Flex align="center" ml="auto" gap={"10px"}>
            {lastUpdate ? (
                <Tag variant='subtle' color="#4319ff" backgroundColor="#efecff" fontSize="14px">
                Ult. Act. {lastUpdate}hs
                </Tag>
            ) : null}
            <Flex alignItems={"center"}>
              <Text mr="2" color="gray.500" fontSize="sm">
                Filtrar por tipo:
              </Text>
              <Select
                value={filterIdTipo}
                onChange={handleFilterChange}
                maxWidth="120px"
                fontSize="sm"
                color="gray.500"
              >
                <option value="all">Todos</option>
                <option value="1">Criptomonedas</option>
                <option value="2">Cedears</option>
                <option value="3">Acciones</option>
              </Select>
            </Flex>
          </Flex>
        )}
      </Flex>

      <Box
        maxWidth="100%"
        overflowX="hidden"
        overflowY={isMobile ? "hidden" : "auto"}
        pb={isMobile ? "20px" : 0}
      >
        {loading ? (
          <Flex
            width="100%"
            justifyContent="center"
            direction="column"
            alignItems="center"
            gap="16px"
            padding="40px"
          >
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap="2px"
            >
              <Heading size="md">Cargando activos...</Heading>
            </Flex>
          </Flex>
        ) : null}

        {filteredData.length > 0 && !loading ? (
          <>
            <Table variant="simple" color="gray.500">
              <Thead>
                {tableInstance.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th
                        key={header.id}
                        pe="10px"
                        borderColor="transparent"
                        p={isMobile ? "8px" : ""}
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                        onClick={header.column.getToggleSortingHandler()}
                        cursor={header.column.getCanSort() ? "pointer" : ""}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {tableInstance.getRowModel().rows.map((row) => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "80px", md: "120px", lg: "auto" }}
                        borderColor="transparent"
                        p={isMobile ? "8px" : ""}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {filteredData.length > 10 ? (
              <Flex direction="column" align="center" gap="10px">
                <Flex justify="center" align="center" gap="16px">
                  <Button
                    onClick={() => tableInstance.firstPage()}
                    disabled={!tableInstance.getCanPreviousPage()}
                  >
                    {"<<"}
                  </Button>
                  <Button
                    onClick={() => tableInstance.previousPage()}
                    disabled={!tableInstance.getCanPreviousPage()}
                  >
                    {"<"}
                  </Button>
                  <Button
                    onClick={() => tableInstance.nextPage()}
                    disabled={!tableInstance.getCanNextPage()}
                  >
                    {">"}
                  </Button>
                  <Button
                    onClick={() => tableInstance.lastPage()}
                    disabled={!tableInstance.getCanNextPage()}
                  >
                    {">>"}
                  </Button>
                </Flex>
                <Text fontSize={"14px"} color="gray.400">
                  {filteredData.length} resultados
                </Text>
              </Flex>
            ) : null}
          </>
        ) : null}
        {filteredData.length === 0 && !loading ? (
          <Flex
            width="100%"
            justifyContent="center"
            direction="column"
            alignItems="center"
            gap="16px"
            padding="40px"
          >
            <Icon fontSize="2xl" color="#4319ff">
              <FaCoins />
            </Icon>
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap="2px"
            >
              <Heading size="md">No se encontraron activos</Heading>
              <Text>
                No encontramos resultados para mostrarte en tu lista de activos
              </Text>
            </Flex>
            <Button
              backgroundColor="#4319FF"
              color="white"
              width="fit-content"
              onClick={openModal}
            >
              Agregar activo
            </Button>
          </Flex>
        ) : null}
        {/* <Table {...getTableProps()} variant="simple" color="gray.500">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor="transparent"
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <React.Fragment key={index}>
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "Ticker") {
                        data = (
                          <Flex align="center">
                            <Avatar
                              src={
                                cell.row.original.logo
                                  ? cell.row.original.logo
                                  : DEFAULT_ICON
                              }
                              w="40px"
                              h="40px"
                              me="8px"
                              backgroundColor="white"
                              borderWidth="1px"
                              borderColor="#eaeaea"
                            />
                            <Flex direction="column">
                              <Text
                                color={textColor}
                                fontSize="sm"
                                fontWeight="600"
                              >
                                {cell.row.original.ticker}
                              </Text>
                              <Text
                                color={textColorSecondary}
                                fontSize="sm"
                                fontWeight="400"
                              >
                                {cell.row.original.nombre}
                              </Text>
                            </Flex>
                          </Flex>
                        );

                      } else if (cell.column.Header === "Tipo") {
                        data = (
                          <Text
                            color={textColorSecondary}
                            fontSize="sm"
                            fontWeight="500"
                          >
                            {cell.row.original.Type.type}
                          </Text>
                        );
                      } else if (cell.column.Header === "Ult. Precio") {
                        data = (
                          <Text
                            color={textColorSecondary}
                            fontSize="sm"
                            fontWeight="500"
                          >
                            {currency === "ARS" ? `$ ${formatter.format(cell.row.original[tipoValor])}` : `US$ ${formatter.format((cell.row.original[tipoValor]).toFixed(2))}`}
                          </Text>
                        );
                      } else if (cell.column.Header === "Cantidad") {
                        data = (
                          <Text
                            color={textColorSecondary}
                            fontSize="sm"
                            fontWeight="500"
                          >
                            {cell.row.original.User_Active.amount}
                          </Text>
                        );
                      } else if (cell.column.Header === "Total") {
                        const totalValue = cell.row.original[tipoValor] * cell.row.original.User_Active.amount
                        data = (
                          <Text
                            color={textColorSecondary}
                            fontSize="sm"
                            fontWeight="500"
                          >
                            {currency === "ARS" ? `$ ${formatter.format(totalValue)}` : `US$ ${formatter.format((totalValue).toFixed(2))}`}
                            </Text>
                        );
                      }
                      else if (cell.column.Header === "Acciones") {
                        data = (
                          <Flex gap="10px">
                            <IconButton size="md" color={textColorSecondary} onClick={() => handleEditModal(cell.row.original)}>
                              <MdModeEdit />
                            </IconButton>
                            <IconButton size="md" color={textColorSecondary} onClick={() => handleDeleteModal(cell.row.original)}>
                              <MdDelete />
                            </IconButton>
                          </Flex>
                        );
                      }
                      return cell.row.original.User_Active.amount !== 0 ? (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "80px", md: "120px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      ) : null;
                    })}
                  </Tr>
                  {isMobile && (
                    <Tr>
                      <Td
                        colSpan={row.cells.length}
                        fontSize="sm"
                        textAlign="flex-end"
                      >
                        <Text fontWeight="600" mt="2" fontSize="xl">
                          Total: US$ {row.original.total.toFixed(2)}
                        </Text>
                      </Td>
                    </Tr>
                  )}
                </React.Fragment>
              );
            })}

          </Tbody>
        </Table>
        {page.length === 0 ? 
              <Flex width="100%" justifyContent="center" direction="column" alignItems="center" gap="16px" padding="40px">
                <Icon fontSize="2xl" color="#4319ff">
                  <FaCoins />
                </Icon>
                <Flex direction="column" justifyContent="center" alignItems="center" gap="2px">
                  <Heading size="md">No se encontraron activos</Heading>
                  <Text>No encontramos resultados para mostrarte en tu lista de activos</Text>
                </Flex>
                <Button
                style={{
                    backgroundColor: "#4319FF",
                    color: "white",
                    width: "fit-content"
                }}
                onClick={openModal}
                >
                  Agregar activo
                </Button>
              </Flex>
            : null} */}
      </Box>

      {/* Modal de sumar activos */}
      <ModalSumarActivos
        isOpen={isModalOpen}
        onClose={closeModal}
        decodedToken={decodedToken}
        actualizar={getUserActives}
        actives={dataUser}
      />

      {/* Modal de quitar activos */}
      <ModalQuitarActivos
        isOpen={isQuitarModalOpen}
        onClose={closeQuitarModal}
        decodedToken={decodedToken}
        actualizar={actualizar}
      />

      {isEditarModalOpen ? (
        <ModalEditarActivo
          isOpen={isEditarModalOpen}
          onClose={closeEditarModal}
          ticker={tickerSelected}
          refreshData={getUserActives}
          amount={{ amount: tickerSelected?.User_Active?.amount }}
        />
      ) : null}

      {isBorrarModalOpen ? (
        <ModalBorrarActivo
          isOpen={isBorrarModalOpen}
          onClose={closeBorrarModal}
          ticker={tickerSelected}
          refreshData={getUserActives}
        />
      ) : null}
    </>
  );
}

export default TopCreatorTable;
