import React, { useEffect, useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  Heading,
  Icon,
  IconButton,
  Image,
  Link,
  Tag,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import {
  MdModeEdit,
  MdDeblur,
  MdDelete,
  MdArrowRightAlt,
  MdChevronRight,
  MdChevronLeft,
  MdArrowDropUp,
  MdArrowDropDown,
  MdArrowDownward,
  MdArrowUpward,
  MdArrowBack,
  MdAdd,
} from "react-icons/md";
import DEFAULT_ICON from "../../../../assets/img/default.png";
import { FaCoins } from "react-icons/fa";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import URL from "constants/url";
import { useCurrency } from "contexts/CurrencyContext";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { TIPO_ICONOS } from "constants/iconos";
import { HSeparator } from "components/separator/Separator";
import dayjs from "dayjs";

function AdminActivosTable() {
  const formatter = new Intl.NumberFormat("es-ES", {
    maximumSignificantDigits: 8,
  });

  const { currency } = useCurrency();
  const tipoValor = currency === "ARS" ? "valor_pesos" : "valor";
  const tipoValorAnterior =
    currency === "ARS" ? "valor_anterior_pesos" : "valor_anterior";

  const tableActivesColumns = [
    {
      id: "ticker",
      header: "Ticker",
      accessorFn: (row) => row.ticker,
      cell: ({ cell }) => {
        console.log(cell);
        return (
          <Flex align="center">
            <Box position="relative">
              <Avatar
                src={
                  cell.row.original.logo ? cell.row.original.logo : DEFAULT_ICON
                }
                w={!isMobile ? "40px" : "30px"}
                h={!isMobile ? "40px" : "30px"}
                me="8px"
                backgroundColor="white"
                borderWidth="1px"
                borderColor="#eaeaea"
              />
              <Avatar
                src={TIPO_ICONOS[cell.row.original.id_tipo]}
                position="absolute"
                w="15px"
                h="15px"
                top="0"
                right="5px"
              />
            </Box>
            <Flex direction="column">
              <Text color={textColor} fontSize="sm" fontWeight="600">
                {cell.row.original.ticker}
              </Text>
              {!isMobile ? (
                <Text
                  color={textColorSecondary}
                  width={"100px"}
                  fontSize="sm"
                  fontWeight="400"
                  whiteSpace={"nowrap"}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                >
                  {cell.row.original.nombre}
                </Text>
              ) : null}
            </Flex>
          </Flex>
        );
      },
      sortingFn: "text",
      enableSorting: true,
    },
    {
      header: "Tipo",
      accessorFn: (row) => row.Type.type,
    },
    {
      header: "Var. 24hs",
      accessorFn: (row) => row.variacion_porcentaje,
      cell: ({ cell }) => {
        return (
          <Text
            fontWeight={600}
            color={
              parseFloat(cell.row.original.variacion_porcentaje) < 0
                ? "red"
                : "#1eb87c"
            }
            display="flex"
            alignItems="center"
            position={"relative"}
          >
            <Icon
              color={
                parseFloat(cell.row.original.variacion_porcentaje) < 0
                  ? "red"
                  : "#1eb87c"
              }
              fontSize={"22px"}
              position={"relative"}
            >
              {parseFloat(cell.row.original.variacion_porcentaje) < 0 ? (
                <BsArrowDownShort />
              ) : (
                <BsArrowUpShort />
              )}
            </Icon>
            {parseFloat(cell.row.original.variacion_porcentaje).toFixed(2)}%
          </Text>
        );
      },
      sortingFn: "basic",
    },
    {
      header: "Precio Anterior",
      cell: ({ cell }) => {
        return (
          <span>
            {currency === "ARS"
              ? `$ ${formatter.format(cell.row.original[tipoValorAnterior])}`
              : `US$ ${formatter.format(cell.row.original[tipoValorAnterior])}`}
          </span>
        );
      },
      accessorFn: (row) => row[tipoValorAnterior],
    },
    {
      header: "Precio Actual",
      accessorFn: (row) => row.valor,
      cell: ({ cell }) => {
        return (
          <Text>
            {currency === "ARS"
              ? `$ ${formatter.format(cell.row.original[tipoValor])}`
              : `US$ ${formatter.format(cell.row.original[tipoValor])}`}
          </Text>
        );
      },
      sortingFn: "basic",
    },
    {
      header: "Acciones",
      cell: ({ cell }) => {
        return (
          <Flex gap="10px">
            <IconButton
              size="md"
              color={textColorSecondary}
              // onClick={() => handleEditModal(cell.row.original)}
            >
              <MdModeEdit />
            </IconButton>
            <IconButton
              size="md"
              color={textColorSecondary}
              // onClick={() => handleDeleteModal(cell.row.original)}
            >
              <MdDelete />
            </IconButton>
          </Flex>
        );
      },
    },
  ];

  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const columns = tableActivesColumns;
  const [loading, setLoading] = useState(true);
  const [actives, setActives] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(null);

  const getActives = async () => {
    setLoading(true);
    try {
      const activos = await axios.get(`${URL}/api/actives`);
      const lastUpdate = await axios.get(`${URL}/api/actives/last_update`);
      setActives(activos.data);
      setLastUpdate(lastUpdate.data.lastUpdate);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getActives();
  }, []);

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 100, //default page size
  });
  const [sorting, setSorting] = useState([]);

  const tableInstance = useReactTable({
    columns,
    data: actives,
    state: {
      pagination,
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
  });

  const textColor = "#1B254B";
  const textColorSecondary = "#A3AED0";

  return (
    <>
      <Flex
        direction={isMobile ? "column" : "row"}
        align={{ sm: "flex-start", lg: "center" }}
        justify={isMobile ? "space-between" : "flex-end"}
        w="100%"
        px="22px"
        pb="20px"
        p={isMobile ? "8px" : ""}
        mb="10px"
        boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
        // mt="30px"
      >
        <Flex
          align="center"
          w={isMobile ? "100%" : "auto"}
          mt={isMobile ? "-55px" : "0"}
        >
          <Flex>
            {!isMobile ? (
              <Button
                variant="action"
                backgroundColor={"#4319FF"}
                fontSize={"sm"}
                color="#fff"
                _hover={{ opacity: "0.8" }}
              >
                Agregar criptomoneda
              </Button>
            ) : (
              <IconButton
                size="md"
                color={"#fff"}
                // onClick={openModal}
                backgroundColor={"#4319FF"}
              >
                <MdAdd />
              </IconButton>
            )}
          </Flex>
        </Flex>
        {isMobile && (
          <Flex
            align="center"
            mt={isMobile ? "10px" : "0"}
            w="100%"
            justify="space-between"
            gap="50px"
          >
            {lastUpdate ? (
              <Tag
                variant="subtle"
                color="#4319ff"
                backgroundColor="#efecff"
                fontSize="14px"
              >
                Ult. Act. {lastUpdate}hs
              </Tag>
            ) : null}
          </Flex>
        )}

        {!isMobile && (
          <Flex align="center" ml="auto" gap={"10px"}>
            {lastUpdate ? (
              <Tag
                variant="subtle"
                color="#4319ff"
                backgroundColor="#efecff"
                fontSize="14px"
              >
                Ult. Act. {lastUpdate}hs
              </Tag>
            ) : null}
          </Flex>
        )}
      </Flex>
      <Box
        maxWidth="100%"
        overflowX="hidden"
        overflowY={isMobile ? "hidden" : "auto"}
        pb={isMobile ? "20px" : 0}
        // padding={"0px 20px"}
      >
        {loading ? (
          <Flex
            width="100%"
            justifyContent="center"
            direction="column"
            alignItems="center"
            gap="16px"
            padding="40px"
          >
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap="2px"
            >
              <Heading size="md">Cargando activos...</Heading>
            </Flex>
          </Flex>
        ) : null}
        {actives.length > 0 ? (
          <>
            <Table variant="simple" color="gray.500">
              {!isMobile ? (
                <Thead>
                  {tableInstance.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        console.log(header);
                        return (
                          <Th
                            key={header.id}
                            pe="10px"
                            cursor={header.column.getCanSort() ? "pointer" : ""}
                            borderColor="transparent"
                            p={isMobile ? "8px" : ""}
                            fontSize={{ sm: "10px", lg: "12px" }}
                            color="gray.400"
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </Th>
                        );
                      })}
                    </Tr>
                  ))}
                </Thead>
              ) : null}
              <Tbody>
                {tableInstance.getRowModel().rows.map((row) => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "80px", md: "120px", lg: "auto" }}
                        borderColor="transparent"
                        p={isMobile ? "8px" : ""}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </>
        ) : null}
        <Flex direction="column" align="center" gap="10px">
          <Flex justify="center" align="center" gap="16px">
            <Button
              onClick={() => tableInstance.firstPage()}
              disabled={!tableInstance.getCanPreviousPage()}
            >
              {"<<"}
            </Button>
            <Button
              onClick={() => tableInstance.previousPage()}
              disabled={!tableInstance.getCanPreviousPage()}
            >
              {"<"}
            </Button>
            <Button
              onClick={() => tableInstance.nextPage()}
              disabled={!tableInstance.getCanNextPage()}
            >
              {">"}
            </Button>
            <Button
              onClick={() => tableInstance.lastPage()}
              disabled={!tableInstance.getCanNextPage()}
            >
              {">>"}
            </Button>
          </Flex>
          <Text fontSize={"14px"} color="gray.400">
            {actives.length} resultados
          </Text>
        </Flex>
        {actives.length === 0 && !loading ? (
          <Flex
            width="100%"
            justifyContent="center"
            direction="column"
            alignItems="center"
            gap="16px"
            padding="40px"
          >
            <Icon fontSize="2xl" color="#4319ff">
              <FaCoins />
            </Icon>
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap="2px"
            >
              <Heading size="md">No se encontraron usuarios</Heading>
              <Text>No hay usuarios registrados</Text>
            </Flex>
          </Flex>
        ) : null}
      </Box>
    </>
  );
}

export default AdminActivosTable;
