// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import ModalDeleteAccount from "components/Modals/DeleteAccount";
import React, { useState } from "react";

export default function Banner(props) {
  const { banner, avatar, name, job, FechaAlta, mail } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClose = () => setIsModalOpen(false)

  
  
  return (
    <>
      <ModalDeleteAccount isOpen={isModalOpen} handleClose={handleClose} />

      <Card mb={{ base: "0px", lg: "20px" }} align="center">
        <Box
          bg={`url(${banner})`}
          bgSize="cover"
          borderRadius="16px"
          h="131px"
          w="100%"
        />
        <Avatar
          mx="auto"
          src={avatar}
          h="87px"
          w="87px"
          mt="-43px"
          border="4px solid"
          borderColor={borderColor}
        />
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="xl"
          mt="10px"
        >
          {name}
        </Text>
        <Text color={textColorSecondary} fontSize="sm">
          {job}
        </Text>
        <Button
          width="fit-content"
          alignSelf="center"
          backgroundColor="red.600"
          color={"#fff"}
          fontSize="sm"
          marginTop="20px"
          onClick={() => setIsModalOpen(true)}
        >
          Borrar cuenta
        </Button>
      </Card>
    </>
  );
}
