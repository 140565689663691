import { Avatar, Flex, Text } from "@chakra-ui/react";
import DEFAULT_ICON from "../../../../assets/img/default.png";

const textColor = "#1B254B";
const textColorSecondary = "#A3AED0";

export const tableActivesColumns = [
  {
    header: "Ticker",
    cell: ({ cell, row }) => {
      console.log(cell)
      return <Flex align="center">
      <Avatar
        src={cell.row.original.logo ? cell.row.original.logo : DEFAULT_ICON}
        w="40px"
        h="40px"
        me="8px"
        backgroundColor="white"
        borderWidth="1px"
        borderColor="#eaeaea"
      />
      <Flex direction="column">
        <Text color={textColor} fontSize="sm" fontWeight="600">
          {cell.row.original.ticker}
        </Text>
        <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
          {cell.row.original.nombre}
        </Text>
      </Flex>
    </Flex>
    },
  },
  {
    header: "Tipo",
    accessorFn: (row) => row.Type.type,
  },
  {
    header: "Ult. Precio",
    accessorKey: "valor",
  },
];
