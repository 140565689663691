import {
  Flex,
  Heading,
  Text,
  UnorderedList,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  Button,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { HSeparator } from "components/separator/Separator";
import URL from "constants/url";
import useObserver from "hooks/useObserver";
import { jwtDecode } from "jwt-decode";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ModalTermsConditions = () => {
  //const ref = useRef(null);

  const location = useLocation();
  const termsAccepted = location.state?.user?.terms;
  const userId = location.state?.user?.id
  const [decodedToken, setDecodedToken] = useState(null);
  const history = useHistory()
  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false)
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false)
  const [isIntersecting, setIsIntersecting] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const [isRef, setIsRef] = useState(false)

  const observerTarget = useRef(null);

  const decodeToken = async () => {
    const decoded = await jwtDecode(token)
    setDecodedToken(decoded)
    setIsTermsModalOpen(!decoded.terms)
  }

  useEffect(() => {
    if(token){
      decodeToken()
    }
  }, [history, token]);

  // useEffect(() => {

  //   console.log(isRef)
  //   console.log(observerTarget.current)
  //   if(!isRef && !observerTarget.current) setIsRef(true)

  //   const observer = new IntersectionObserver(
  //     entries => {
  //       if (entries[0].isIntersecting) {
  //         setIsIntersecting(true)
  //       }
  //     },
  //     { threshold: 1 }
  //   );
  
  //   if (observerTarget.current) {
  //     observer.observe(observerTarget.current);
  //   }
  
  //   return () => {
  //     if (observerTarget.current) {
  //       observer.unobserve(observerTarget.current);
  //     }
  //   };
  // }, [isRef, isTermsModalOpen]);

  const observe = useCallback(el => {
    if(!observerTarget.current) { // init the observer if needed
      observerTarget.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
                   setIsIntersecting(true)
               }
      });
      observerTarget.current.observe(el); // observe the element
    }

  }, []);

  useEffect(() => () => { // cleanup
    observerTarget?.current?.disconnect();
  }, []);

  const acceptTerms = async () => {
    try {
      setLoading(true)
      console.log(decodedToken)
      const terms = await axios.put(`${URL}/api/auth/terms_conditions`, { userId: decodedToken.userId })
      if(terms.status === 200){
        localStorage.setItem("token", terms.data.token)
        setLoading(false)
        setIsTermsModalOpen(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  
  const handleChange = (e) => {
    const { checked } = e.target
    setIsChecked(checked)
  }

  return (
    <div>
      <Modal isOpen={isTermsModalOpen} scrollBehavior={"inside"}>
        <ModalOverlay />
        <ModalContent borderRadius="20px" position={"relative"} maxW={"800px"}>
          <ModalHeader>Terminos y Condiciones</ModalHeader>
          <ModalBody position="relative">
            <Flex
              flexDirection="column"
              gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
              gap="16px"
              backgroundColor={"#fff"}
              borderWidth="1px"
              borderColor="#eaeaea"
              borderRadius={"6px"}
              padding={"20px"}
              fontFamily={"Georgia, serif"}
            >
              <Text>
                Por favor, lea detenidamente los presentes términos y
                condiciones de uso (en adelante, los "Términos y Condiciones")
                antes de registrarse y utilizar la plataforma Cartera Argenta
                (en adelante, la "Plataforma"). Al registrarse, usted declara
                haber leído, comprendido y aceptado íntegramente estos Términos
                y Condiciones.
              </Text>
              <HSeparator />
              <Heading size="sm">1. Objeto de la Plataforma</Heading>
              <Text>
                Cartera Argenta es una herramienta diseñada para permitir la
                consulta, registro y visualización de información financiera
                relativa a activos del mercado argentino y criptomonedas, basada
                en datos obtenidos de terceros y fuentes públicas disponibles.
              </Text>
              <UnorderedList>
                <li>
                  Carácter Informativo: La información proporcionada en la
                  Plataforma tiene carácter meramente informativo y referencial.
                  Los valores y estimaciones mostrados no constituyen
                  recomendaciones de inversión ni asesoramiento financiero,
                  contable, impositivo o legal.
                </li>
                <li>
                  Limitación de Responsabilidad: Cartera Argenta no garantiza la
                  precisión, exactitud, vigencia o completitud de los datos
                  proporcionados. Por lo tanto, la utilización de esta
                  información es bajo exclusiva responsabilidad del usuario..
                </li>
              </UnorderedList>

              <HSeparator />

              <Heading size="sm">2. Exclusión de Responsabilidad</Heading>
              <Text>Cartera Argenta no se responsabiliza por:</Text>
              <UnorderedList>
                <li>
                  Errores, inexactitudes u omisiones en la información mostrada
                  en la Plataforma, incluyendo las derivadas de actualizaciones
                  o cambios en los datos proporcionados por terceros.
                </li>
                <li>
                  Decisiones o acciones tomadas por los usuarios basadas en la
                  información proporcionada por la Plataforma.
                </li>
                <li>
                  Daños y perjuicios de cualquier naturaleza, ya sean directos,
                  indirectos, emergentes o lucrativos, derivados del uso de la
                  Plataforma.
                </li>
              </UnorderedList>
              <Text>
                El usuario reconoce que los valores y análisis proporcionados
                son aproximados y especulativos, pudiendo no reflejar la
                realidad del mercado en tiempo real.
              </Text>

              <HSeparator />

              <Heading size="sm">3. Uso Permitido de la Plataforma</Heading>
              <Text>
                El acceso y uso de la Plataforma se limita exclusivamente a
                fines lícitos y personales. El usuario se compromete a:
              </Text>
              <UnorderedList>
                <li>
                  Utilizar la Plataforma de conformidad con la legislación
                  vigente en la República Argentina y cualquier otra normativa
                  aplicable.
                </li>
                <li>
                  Abstenerse de utilizar la Plataforma para actividades
                  ilícitas, incluyendo, pero no limitado a, fraude financiero,
                  lavado de activos u otras conductas prohibidas por la ley.
                </li>
                <li>
                  Proveer información veraz y completa al momento del registro y
                  durante el uso de la Plataforma.
                </li>
              </UnorderedList>
              <Text>
                Cartera Argenta se reserva el derecho de restringir o cancelar
                el acceso de cualquier usuario que incumpla con estos Términos y
                Condiciones, sin previo aviso.
              </Text>

              <HSeparator />

              <Heading size="sm">4. Políticas de Privacidad</Heading>
              <Text>
                Cartera Argenta recolecta y procesa datos personales del usuario
                en conformidad con la Ley N° 25.326 (Ley de Protección de los
                Datos Personales) y demás normativas aplicables en la República
                Argentina.
              </Text>
              <UnorderedList>
                <li>
                  Datos Recolectados: Incluyen, entre otros, nombre, correo
                  electrónico e información de inicio de sesión.
                </li>
                <li>
                  Uso de los Datos: Los datos se utilizarán exclusivamente para
                  el funcionamiento de la Plataforma y no serán compartidos con
                  terceros, salvo autorización expresa del usuario o
                  requerimiento legal.
                </li>
                <li>
                  Seguridad: La Plataforma implementa medidas de seguridad
                  razonables para proteger los datos personales de los usuarios,
                  aunque no puede garantizar una seguridad absoluta contra
                  vulneraciones.
                </li>
              </UnorderedList>

              <HSeparator />

              <Heading size="sm">
                5. Limitación de Responsabilidad Técnica
              </Heading>
              <Text>
                Cartera Argenta no será responsable por interrupciones en el
                servicio, demoras o errores derivados de problemas técnicos,
                mantenimiento, actualizaciones o cualquier otra causa fuera de
                su control razonable.
              </Text>
              <Text>
                Asimismo, la Plataforma no garantiza disponibilidad continua ni
                libre de fallas, ni se responsabiliza por posibles daños a los
                dispositivos o conexiones de los usuarios resultantes del uso de
                la misma.
              </Text>

              <HSeparator />

              <Heading size="sm">
                6. Modificaciones a los Términos y Condiciones
              </Heading>
              <Text>
                Cartera Argenta se reserva el derecho de modificar, actualizar o
                eliminar parcial o totalmente los presentes Términos y
                Condiciones en cualquier momento. Dichas modificaciones serán
                notificadas mediante el correo electrónico registrado o al
                ingresar nuevamente en la Plataforma, debiendo el usuario
                aceptar los términos actualizados para continuar utilizando el
                servicio.
              </Text>

              <HSeparator />

              <Heading size="sm">7. Jurisdicción y Ley Aplicable</Heading>
              <Text>
                Los presentes Términos y Condiciones se rigen por las leyes de
                la República Argentina. Cualquier controversia derivada del uso
                de la Plataforma será sometida a la jurisdicción exclusiva de
                los tribunales ordinarios de la Ciudad Autónoma de Buenos Aires.
              </Text>

              <HSeparator />
              <Heading size="sm">
                Aceptación y Consentimiento
              </Heading>
              <Text ref={observe}>
                Al registrarse, el usuario declara expresamente haber leído,
                comprendido y aceptado íntegramente los presentes Términos y
                Condiciones. Asimismo, acepta que los valores y datos mostrados
                son aproximados y que Cartera Argenta no se hace responsable por
                las decisiones que el usuario tome basándose en dicha
                información.
              </Text>

              <HSeparator />
              <Text>
                Si tiene preguntas, puede contactarnos en:
                info@cartera-argenta.com
              </Text>
              <Text>Fecha de actualización: 18/11/2024</Text>
            </Flex>
          </ModalBody>
          <ModalFooter
            // position="sticky"
            // bottom="0px"
            width="100%"
            margin="0 auto"
            alignSelf="center"
            padding={"10px 20px"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Checkbox disabled={!isIntersecting} onChange={handleChange} fontSize="12px" color={"gray.500"}>
              Acepto los terminos y condiciones
            </Checkbox>
            <Button
              variant="solid"
              backgroundColor="#4319FF"
              color="#fff"
              size="sm"
              fontWeight="500"
              _hover={{ opacity: '0.7' }}
              onClick={acceptTerms}
              disabled={!isChecked}
              >
              {loading ? (
                <Spinner size="sm" />
              ) : (
                "Confirmar"
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ModalTermsConditions;
