import React, { useEffect, useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  Heading,
  Icon,
  IconButton,
  Image,
  Link,
  Tag,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import {
  MdModeEdit,
  MdDeblur,
  MdDelete,
  MdArrowRightAlt,
  MdChevronRight,
  MdChevronLeft,
  MdArrowDropUp,
  MdArrowDropDown,
  MdArrowDownward,
  MdArrowUpward,
  MdArrowBack,
} from "react-icons/md";
import DEFAULT_ICON from "../../../../assets/img/default.png";
import { FaCoins } from "react-icons/fa";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import URL from "constants/url";
import { useCurrency } from "contexts/CurrencyContext";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { TIPO_ICONOS } from "constants/iconos";
import { HSeparator } from "components/separator/Separator";

function ActivesTable({ tipo, nombreTipo, handleType, selectedType }) {
  const formatter = new Intl.NumberFormat("es-ES", { maximumSignificantDigits: 8 });

  const tableActivesColumns = [
    {
      id: 'ticker',
      header: 'Ticker',
      accessorFn: (row) => row.ticker,
      cell: ({ cell }) => {
        console.log(cell);
        return (
          <Flex align="center">
            <Box position="relative">
              <Avatar
                src={
                  cell.row.original.logo ? cell.row.original.logo : DEFAULT_ICON
                }
                w={selectedType && !isMobile ? "40px" : "30px"}
                h={selectedType && !isMobile ? "40px" : "30px"}
                me="8px"
                backgroundColor="white"
                borderWidth="1px"
                borderColor="#eaeaea"
              />
              <Avatar
                src={TIPO_ICONOS[cell.row.original.id_tipo]}
                position="absolute"
                w="15px"
                h="15px"
                top="0"
                right="5px"
              />
            </Box>
            <Flex direction="column">
              <Text color={textColor} fontSize="sm" fontWeight="600">
                {cell.row.original.ticker}
              </Text>
              {!isMobile && selectedType ? (
                <Text
                  color={textColorSecondary}
                  width={selectedType ? "" : "100px"}
                  fontSize="sm"
                  fontWeight="400"
                  whiteSpace={selectedType ? "" : "nowrap"}
                  overflow={selectedType ? "" : "hidden"}
                  textOverflow={selectedType ? "" : "ellipsis"}
                >
                  {cell.row.original.nombre}
                </Text>
              ) : null}
            </Flex>
          </Flex>
        );
      },
      sortingFn: "text",
      enableSorting: true
    },
    // {
    //   header: "Tipo",
    //   accessorFn: (row) => row.Type.type,
    // },

    {
      header: selectedType ? "Variación 24hs" : "Var. 24hs",
      accessorFn: (row) => row.variacion_porcentaje,
      cell: ({ cell }) => {
        return (
          <Text
            fontWeight={600}
            color={
              parseFloat(cell.row.original.variacion_porcentaje) < 0
                ? "red"
                : "#1eb87c"
            }
            display="flex"
            alignItems="center"
            position={"relative"}
          >
            <Icon
              color={
                parseFloat(cell.row.original.variacion_porcentaje) < 0
                  ? "red"
                  : "#1eb87c"
              }
              fontSize={"22px"}
              position={"relative"}
            >
              {parseFloat(cell.row.original.variacion_porcentaje) < 0 ? (
                <BsArrowDownShort />
              ) : (
                <BsArrowUpShort />
              )}
            </Icon>
            {parseFloat(cell.row.original.variacion_porcentaje).toFixed(2)}%
          </Text>
        );
      },
      sortingFn: "basic",
    },
    {
      header: selectedType ? "Precio Actual" : "Precio",
      accessorFn: (row) => row.valor,
      cell: ({ cell }) => {
        return (
          <Text>
            {currency === "ARS"
              ? `$ ${formatter.format(cell.row.original[tipoValor])}`
              : `US$ ${formatter.format(
                  cell.row.original[tipoValor]
                )}`}
          </Text>
        );
      },
      sortingFn: "basic",
    },
  ];

  const [isMobile] = useMediaQuery("(max-width: 768px)");
  //const columns = useMemo(() => tableActivesColumns, [tableActivesColumns]);
  const columns = tableActivesColumns
  const [loading, setLoading] = useState(true);
  const [dataUser, setDataUser] = useState([]);
  // const [lastUpdate, setLastUpdate] = useState(null);

  const { currency } = useCurrency();
  const tipoValor = currency === "ARS" ? "valor_pesos" : "valor";
  const tipoValorAnterior =
    currency === "ARS" ? "valor_anterior_pesos" : "valor_anterior";

  if (selectedType) {
    tableActivesColumns.push({
      header: "Precio Anterior",
      cell: ({ cell }) => {
        return (
          <span>
            {currency === "ARS"
              ? `$ ${formatter.format(cell.row.original[tipoValorAnterior])}`
              : `US$ ${formatter.format(
                  cell.row.original[tipoValorAnterior].toFixed(2)
                )}`}
          </span>
        );
      },
      sortingFn: "basic",
    });
  }

  const getUserActives = async () => {
    setLoading(true);
    try {
      const data = await axios.get(`${URL}/api/actives`);
      // const lastUpdate = await axios.get(`${URL}/api/actives/last_update`);
      const filteredData = data.data.filter(
        (item) => item.id_tipo === parseInt(tipo)
      );
      console.log(filteredData)
      setDataUser(filteredData);
      // setLastUpdate(lastUpdate.data.lastUpdate);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserActives();
  }, [tipo]);

  // const data = useMemo(
  //   () =>
  //     dataUser
  //       ? dataUser.map((item) => ({
  //           ...item,
  //         }))
  //       : [],
  //   [dataUser]
  // );

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: selectedType ? 50 : 10, //default page size
  });
  const [sorting, setSorting] = useState([]);

  const tableInstance = useReactTable({
    columns,
    data: dataUser,
    state: {
      pagination,
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
  });

  const textColor = "#1B254B";
  const textColorSecondary = "#A3AED0";

  return (
    <>
      {/* <Flex
        direction={isMobile ? "column" : "row"}
        align={{ sm: "flex-start", lg: "center" }}
        justify={isMobile ? "space-between" : "center"}
        w="100%"
        px="22px"
        pb="20px"
        mb="10px"
        boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
        mt="30px"
      >
        <Text
          color={textColor}
          fontSize="xl"
          fontWeight="600"
          mb={isMobile ? "10px" : "0"}
        >
          Lista de activos disponibles
        </Text>
        {isMobile && (
          <Flex align="center" mt={isMobile ? "10px" : "0"} w="100%">
            <Select
              value={filterIdTipo}
              onChange={handleFilterChange}
              fontSize="sm"
              color="gray.500"
              flex="1"
            >
              <option value="all">Todos</option>
              <option value="1">Criptomonedas</option>
              <option value="2">Cedears</option>
              <option value="3">Acciones</option>
            </Select>
          </Flex>
        )}

        {!isMobile && (
          <Flex align="center" ml="auto">
            <Text mr="5" color="gray.500" fontSize="sm">
              Filtrar por tipo:
            </Text>
            <Select
              value={filterIdTipo}
              onChange={handleFilterChange}
              maxWidth="120px"
              fontSize="sm"
              color="gray.500"
            >
              <option value="all">Todos</option>
              <option value="1">Criptomonedas</option>
              <option value="2">Cedears</option>
              <option value="3">Acciones</option>
            </Select>
          </Flex>
        )}
      </Flex> */}

      {selectedType ? (
                      <Flex
                      gap="6px"
                      align="center"
                      onClick={() => handleType(null)}
                      padding={"0px 20px 10px 20px"}
                    >
                      <Icon
                        as={MdArrowBack}
                        color="#4319FF"
                        w="14px"
                        h="14px"
                        fontWeight="2000"
                      />
                      <Text cursor={"pointer"} color="#4319FF" fontWeight="500">
                        Volver a lista de activos
                      </Text>
                    </Flex>
 
      ) : null}
      <Box
        maxWidth="100%"
        overflowX="hidden"
        overflowY={isMobile ? "hidden" : "auto"}
        pb={isMobile ? "20px" : 0}
        // padding={"0px 20px"}
      >
        {loading ? (
          <Flex
            width="100%"
            justifyContent="center"
            direction="column"
            alignItems="center"
            gap="16px"
            padding="40px"
          >
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap="2px"
            >
              <Heading size="md">Cargando activos...</Heading>
            </Flex>
          </Flex>
        ) : null}
        {dataUser.length > 0 ? (
          <>

            <Flex
              padding={"0px 20px 0 20px"}
              gap={"10px"}
              justify={"space-between"}
              align={"center"}
              width="100%"
              onClick={() => handleType(tipo)}
              cursor={"pointer"}
            >
              <Flex align={"center"} justify={"center"} gap={"6px"}>
                <Image src={TIPO_ICONOS[tipo]} width={"30px"} />
                <Heading size="sm" fontWeight="600" color={"#1B2559"}>
                  {nombreTipo}
                </Heading>
              </Flex>
              {/* {selectedType && lastUpdate ? (
                <Tag variant='subtle' color="#4319ff" backgroundColor="#efecff" fontSize="14px">
                Ult. Act.: {lastUpdate}hs
                </Tag>
              ) : null} */}
              {!selectedType ? (
                <Icon color={"#4319FF"} fontSize={"24px"} position={"relative"}>
                  <MdChevronRight />
                </Icon>
              ) : null}
            </Flex>
            <HSeparator margin="20px 0 10px 0" />
            <Table variant="simple" color="gray.500">
              {!isMobile ?
              <Thead>
                {tableInstance.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      console.log(header)
                      return (
                      <Th
                        key={header.id}
                        pe="10px"
                        cursor={header.column.getCanSort() ? "pointer" : ""}
                        borderColor="transparent"
                        p={isMobile ? "8px" : ""}
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Th>
                      )
                      })}
                  </Tr>
                ))}
              </Thead>
              : null}
              <Tbody>
                {tableInstance.getRowModel().rows.map((row) => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "80px", md: "120px", lg: "auto" }}
                        borderColor="transparent"
                        p={isMobile ? "8px" : ""}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <HSeparator margin="20px 0 20px 0" />

            {selectedType ? (
              <Flex direction="column" align="center" gap="10px">
                <Flex justify="center" align="center" gap="16px">
                  <Button
                    onClick={() => tableInstance.firstPage()}
                    disabled={!tableInstance.getCanPreviousPage()}
                  >
                    {"<<"}
                  </Button>
                  <Button
                    onClick={() => tableInstance.previousPage()}
                    disabled={!tableInstance.getCanPreviousPage()}
                  >
                    {"<"}
                  </Button>
                  <Button
                    onClick={() => tableInstance.nextPage()}
                    disabled={!tableInstance.getCanNextPage()}
                  >
                    {">"}
                  </Button>
                  <Button
                    onClick={() => tableInstance.lastPage()}
                    disabled={!tableInstance.getCanNextPage()}
                  >
                    {">>"}
                  </Button>
                </Flex>
                <Text fontSize={"14px"} color="gray.400">
                  {dataUser.length} resultados
                </Text>
              </Flex>
            ) : (
              <Flex width={"100%"} justify={"center"} margin={"0px 0"}>
                <Link
                  fontWeight={"600"}
                  color={"#4319FF"}
                  fontSize={"sm"}
                  onClick={() => handleType(tipo)}
                >
                  Ver más
                </Link>
              </Flex>
            )}
          </>
        ) : null}
        {dataUser.length === 0 && !loading ? (
          <Flex
            width="100%"
            justifyContent="center"
            direction="column"
            alignItems="center"
            gap="16px"
            padding="40px"
          >
            <Icon fontSize="2xl" color="#4319ff">
              <FaCoins />
            </Icon>
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap="2px"
            >
              <Heading size="md">No se encontraron activos</Heading>
              <Text>
                No encontramos resultados para mostrarte en tu lista de activos
              </Text>
            </Flex>
          </Flex>
        ) : null}
      </Box>
    </>
  );
}

export default ActivesTable;
