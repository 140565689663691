export const tableColumnsTopCreators = [
  {
    header: "Ticker",
    accessorKey: "ticker",
  },
  {
    header: "Tipo",
    accessorKey: "tipo",
  },
  {
    header: "Ult. Precio",
    accessorKey: "valor",
  },
  {
    header: "Cantidad",
    accessorKey: "amount",
  },
  {
    header: "Total",
    accessorKey: "total",
  },
  {
    header: "Acciones",
    accessorKey: "acciones",
  }
];
