import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Text,
  useColorModeValue,
  Spinner,
  useToast,
  Stack,
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import DefaultAuth from "layouts/auth/Default";
import { FaCheck } from "react-icons/fa";
import illustration from "assets/img/auth/auth.png";
import URL from "constants/url";

function Contraseña() {
  const [user, setUser] = useState(null)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("")
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const history = useHistory();
  const toast = useToast();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");
  console.log(token);

  useEffect(() => {
    const verfiyEmail = async () => {
      if (token) {
        const response = await fetch(
          `${URL}/api/auth/verify_token_password`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token }),
          }
        );
        const res = await response.json()
        if (response.status === 200) {
            console.log(res)
          setIsTokenVerified(true);
          setUser(res.user.Email)
          toast({
            title: "¡Token verificado!",
            description: "Ya podés cambiar tu contraseña",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
        }
      }
    };
    verfiyEmail();
  }, [token]);

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(email));
  };

  const validatePassword = () => {
    setIsPasswordValid(password.length >= 7);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if (!isEmailValid) {
        return;
      }

      const response = await fetch(
        `${URL}/api/auth/forgot_password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json().catch((error) => {
        console.error("Error al procesar la respuesta JSON:", error);
      });

      console.log(data); // Imprime la respuesta completa para debug

      if (response.ok) {
        setLoginStatus("success");
        toast({
          title: "¡Link enviado!",
          description: "Revisá tu correo",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } else {
        setLoginStatus("error");
        toast({
          title: "Error en el inicio de sesión",
          description:
            data?.error === "User is not verified"
              ? "Usuario no verificado."
              : "Credenciales incorrectas. Inténtalo de nuevo.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      setLoginStatus("error");
      toast({
        title: "Error en el inicio de sesión",
        description:
          "Hubo un problema con el inicio de sesión. Inténtalo de nuevo.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleModifyPassword = async () => {
    try {
      setLoading(true);

      console.log(user)

      if (!isPasswordValid || (password !== repeatedPassword)) {
        return;
      }

      const response = await fetch(
        `${URL}/api/auth/change_password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: user, password }),
        }
      );

      const data = await response.json().catch((error) => {
        console.error("Error al procesar la respuesta JSON:", error);
      });

      console.log(data); // Imprime la respuesta completa para debug

      if (response.ok) {
        setLoginStatus("success");
        toast({
          title: "¡Contraseña modificada!",
          description: "Iniciá sesión con tu nueva contraseña",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        setTimeout(() => {
            // Redireccionar a la página de inicio u otra página después del inicio de sesión
            history.push("/auth-iniciar-sesion");
          }, 2000);
      } else {
        setLoginStatus("error");
        toast({
          title: "Error en el inicio de sesión",
          description:
            data?.error === "User is not verified"
              ? "Usuario no verificado."
              : "Credenciales incorrectas. Inténtalo de nuevo.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      setLoginStatus("error");
      toast({
        title: "Error en el inicio de sesión",
        description:
          "Hubo un problema con el inicio de sesión. Inténtalo de nuevo.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => setShow(!show);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading
            color={useColorModeValue("navy.700", "white")}
            fontSize="36px"
            mb="10px"
          >
            Cambiar contraseña
          </Heading>
        </Box>
        {!token ? (
          <>
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
              gap="4"
            >
              <Text>
                Ingresá el correo que usaste al registrarte y te enviaremos un
                link para cambiar tu contraseña.
              </Text>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  mb="8px"
                >
                  Email
                  <Text color={isEmailValid ? "#82FFC9" : "#4319FF"}>*</Text>
                </FormLabel>
                <InputGroup>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="email"
                    placeholder="michaelscott@dundermifflin.com"
                    mb="24px"
                    fontWeight="500"
                    size="lg"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      validateEmail();
                    }}
                  />
                  {isEmailValid && (
                    <InputRightElement>
                      <Icon as={FaCheck} color="#82FFC9" />
                    </InputRightElement>
                  )}
                </InputGroup>

                <Button
                  type="submit"
                  onClick={handleSubmit}
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                  backgroundColor={loginStatus === "success" ? "#82FFC9" : ""}
                  disabled={
                    loading || loginStatus === "success" || !isEmailValid
                  }
                >
                  {loading ? (
                    <Spinner size="sm" />
                  ) : loginStatus === "success" ? (
                    "¡Link enviado!"
                  ) : (
                    "Enviar link"
                  )}
                </Button>
              </FormControl>
            </Flex>
          </>
        ) : null}

        {token && isTokenVerified ? (
          <>
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
              gap="4"
            >
              <Text>Ingresá tu nueva contraseña.</Text>
              <FormControl>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  display="flex"
                >
                  Contraseña
                  <Text color={isPasswordValid ? "#82FFC9" : "#4319FF"}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    placeholder="Min. 8 caracteres"
                    mb="24px"
                    size="lg"
                    type={show ? "text" : "password"}
                    variant="auth"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      validatePassword();
                    }}
                  />

                  {isPasswordValid && (
                    <InputRightElement>
                      <Icon as={FaCheck} color="#82FFC9" />
                    </InputRightElement>
                  )}
                  <InputLeftElement>
                    <Icon
                      _hover={{ cursor: "pointer" }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputLeftElement>
                </InputGroup>

                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  display="flex"
                >
                  Repetir password
                  <Text color={isPasswordValid ? "#82FFC9" : "#4319FF"}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    placeholder="Min. 8 caracteres"
                    mb="24px"
                    size="lg"
                    type={show ? "text" : "password"}
                    variant="auth"
                    value={repeatedPassword}
                    onChange={(e) => {
                      setRepeatedPassword(e.target.value);
                      validatePassword();
                    }}
                  />

                  {isPasswordValid && (
                    <InputRightElement>
                      <Icon as={FaCheck} color="#82FFC9" />
                    </InputRightElement>
                  )}
                  <InputLeftElement>
                    <Icon
                      _hover={{ cursor: "pointer" }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputLeftElement>
                </InputGroup>

                <Button
                  type="submit"
                  onClick={handleModifyPassword}
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                  backgroundColor={loginStatus === "success" ? "#82FFC9" : ""}
                  disabled={
                    loading || loginStatus === "success" || !isPasswordValid
                  }
                >
                  {loading ? (
                    <Spinner size="sm" />
                  ) : loginStatus === "success" ? (
                    "¡Contraseña modificada!"
                  ) : (
                    "Cambiar contraseña"
                  )}
                </Button>
              </FormControl>
            </Flex>
          </>
        ) : null}
      </Flex>
    </DefaultAuth>
  );
}

export default Contraseña;
