import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

// Chakra imports
import {
  Box,
  Flex,
  Grid,
  SimpleGrid,
  Tag,
  useMediaQuery,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
import { tableActivesColumns } from "./variables/tableActives";
import axios from "axios";
import URL from "constants/url";
import AdminActivosTable from "./components/AdminActivosTable";

// Assets

export default function AdminActivos() {
  const history = useHistory();
  const [decodedToken, setDecodedToken] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      history.push("/auth/iniciar-sesion");
    } else {
      (async () => {
        setDecodedToken(await jwtDecode(token));
      })();

      if (decodedToken && decodedToken?.exp * 1000 < Date.now()) {
        history.push("/auth/iniciar-sesion");
      }
    }
  }, [history]);

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px", sm: "80px" }}>
      <Grid
        mb="20px"
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block" }}
      >
        <Flex
          flexDirection="column"
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
        >
          <Grid templateColumns="repeat(1, 1fr)" gap="5">
            <Card px="0px" marginTop="30px">
              <AdminActivosTable />
            </Card>
          </Grid>
        </Flex>
      </Grid>
    </Box>
  );
}
