import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Image,
  SimpleGrid,
  Tag,
  Text,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import Usa from "assets/img/dashboards/usa.png";
import { getPieChartData } from "variables/charts";
import Dblue from "assets/img/dashboards/dblue.png";
import Arg from "assets/img/dashboards/arg.png";
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect } from "react";
import { MdCurrencyBitcoin, MdAccountBalance } from "react-icons/md";
import PieCard from "views/admin/default/components/PieCard";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useHistory } from "react-router-dom";
import URL from "constants/url";
import { useCurrency } from "contexts/CurrencyContext";
import DOLAR_BLUE_LOGO from "../../../assets/img/dolar_blue_icon.png";
import ARGENTINA_ICON from "../../../assets/img/argentina_icon.png";
import USA_ICON from "../../../assets/img/usa_icon.png";
import ModalTermsConditions from "components/Modals/TermsConditions";
import DOLAR_BLUE from "../../../assets/img/DOLAR_BLUE.svg";
import { TIPO_ICONOS } from "constants/iconos";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DoughnutChart from "components/DonutChart";
import Card from "components/card/Card";

const UserReports = () => {
  const [isUsaSelected, setIsUsaSelected] = useState(false);
  const history = useHistory();
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "secondaryGray.300");
  const [criptoValue, setCriptoValue] = useState(null);
  const [mervalValue, setMervalValue] = useState(null);
  const [accionesValue, setAccionesValue] = useState(null);
  const [totalInversiones, setTotalInversiones] = useState(null);
  const [dolarBlueValue, setDolarBlueValue] = useState(null);
  const [porcentMerval, setPorcentMerval] = useState(0);
  const [porcentCrypto, setPorcentCrypto] = useState(0);
  const [porcentAcciones, setPorcentAcciones] = useState(0);
  const [pieChartData, setPieChartData] = useState([]);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [userActives, setUserActives] = useState({})
  const [userActivesData, setUserActivesData] = useState([])

  const { currency } = useCurrency();
  const tipo_valor = currency === "ARS" ? "valor_pesos" : "valor";
  const formatter = new Intl.NumberFormat("es-ES", {});
  const [lastUpdate, setLastUpdate] = useState(null)
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const getLastUpdate = async () => {
    try {
      const lastUpdate = await axios.get(`${URL}/api/actives/last_update`);
      setLastUpdate(lastUpdate.data.lastUpdate);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLastUpdate()
  }, [])

  console.log(currency);

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log(token);

    if (!token) {
      history.push("/auth/iniciar-sesion");
    } else {
      const decodedToken = jwtDecode(token);
      console.log(decodedToken);
      axios
        .get(`${URL}/api/actives/${decodedToken.userId}`)
        .then((resp) => {
          let sumCripto = 0;
          let sumMerval = 0;
          let sumAcciones = 0;



          resp.data.Actives.forEach((elem) => {
            console.log(elem);
            if (elem.id_tipo === 1) {
              let aux = elem[tipo_valor] * elem.User_Active.amount;
              sumCripto += aux;
            }
            if (elem.id_tipo === 2) {
              let aux = elem[tipo_valor] * elem.User_Active.amount;
              sumMerval += aux;
            }
            if (elem.id_tipo === 3) {
              let aux = elem[tipo_valor] * elem.User_Active.amount;
              sumAcciones += aux;
            }
          });

          // Calcular los porcentajes en función de los valores originales en dólares

          const total = sumCripto + sumMerval + sumAcciones;
          const totalInversiones =
            currency === "ARS"
              ? `$${formatter.format(
                  (sumCripto + sumMerval + sumAcciones).toFixed(2)
                )}`
              : `US$ ${formatter.format(
                  (sumCripto + sumMerval + sumAcciones).toFixed(2)
                )}`;

                let porcentajesActivos = resp.data.Actives.map(item => {
                  const porcentaje = ((item[tipo_valor] * item.User_Active.amount) * 100) / total
                  return { porcentaje, ticker: item.ticker, valor: item.valor, color: item.color, logo: item.logo }
                })
                porcentajesActivos.sort((a,b) => b.porcentaje - a.porcentaje);

                if(porcentajesActivos.length > 0){
                  const data = {
                    labels: porcentajesActivos.map(item => `${item.ticker} (${item.porcentaje.toFixed(2)}%)`),
                    datasets: [{
                      label: '',
                      data: porcentajesActivos.map(item => item.porcentaje),
                      backgroundColor: porcentajesActivos.map(item => item.color),
                      hoverOffset: 4
                    }]
                  };
                  setUserActives(data);

                  if(porcentajesActivos.length > 5){
                    const filtroActivos = porcentajesActivos.filter((_, index) => index >= 5)
                    const porcentajeOtros = filtroActivos.reduce((acc, value, index) => {
                      acc += value.porcentaje;
                      return acc;
                    }, 0);
                    porcentajesActivos = porcentajesActivos.filter((_, index) => index < 5)
                    porcentajesActivos.push({ porcentaje: porcentajeOtros, ticker: "Otros", valor: null, color: "", logo: null })
                  } 

                  setUserActivesData(porcentajesActivos)
                }


          const porcentMerval = (sumMerval * 100) / total;
          const porcentCrypto = (sumCripto * 100) / total;
          const porcentAcciones = (sumAcciones * 100) / total;

          console.log(porcentCrypto);

          setCriptoValue(
            currency === "ARS"
              ? `$ ${formatter.format(sumCripto)}`
              : `US$ ${formatter.format(sumCripto.toFixed(2))}`
          );
          setMervalValue(
            currency === "ARS"
              ? `$ ${formatter.format(sumMerval)}`
              : `US$ ${formatter.format(sumMerval.toFixed(2))}`
          );
          setAccionesValue(
            currency === "ARS"
              ? `$ ${formatter.format(sumAcciones)}`
              : `US$ ${formatter.format(sumAcciones.toFixed(2))}`
          );

          // Guardar los valores y porcentajes actualizados
          setTotalInversiones(totalInversiones);
          setPorcentAcciones(porcentAcciones);
          setPorcentMerval(porcentMerval);
          setPorcentCrypto(porcentCrypto);

          // Verificar si los porcentajes son NaN o no están definidos
          isNaN(porcentMerval)
            ? setPorcentMerval(0)
            : setPorcentMerval(porcentMerval);

          isNaN(porcentCrypto)
            ? setPorcentCrypto(0)
            : setPorcentCrypto(porcentCrypto);

          isNaN(porcentAcciones)
            ? setPorcentAcciones(0)
            : setPorcentAcciones(porcentAcciones);
        })

        .catch((error) => {
          console.error("Error fetching actives:", error);
        });
      if (decodedToken && decodedToken?.exp * 1000 < Date.now()) {
        history.push("/auth/iniciar-sesion");
      }
    }
  }, [history, dolarBlueValue, currency]);

  useEffect(() => {
    axios
      .get("https://api.bluelytics.com.ar/v2/latest")
      .then((response) => {
        const blueValue = response.data.blue.value_avg;
        console.log("Dólar Blue Value:", blueValue);
        setDolarBlueValue(blueValue);
      })
      .catch((error) => {
        console.error("Error fetching blue dollar value:", error);
      });
  }, []);

  useEffect(() => {
    setPieChartData([
      Number(porcentMerval.toFixed(2)),
      Number(porcentCrypto.toFixed(2)),
      Number(porcentAcciones.toFixed(2)),
    ]);
  }, [porcentCrypto, porcentMerval, porcentAcciones]);

  return (
    <>
      <ModalTermsConditions />
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} mt={isMobile ? "0" : "30px"} display="flex" flexDirection={"column"}>
        {lastUpdate ?
        <Tag
          variant="subtle"
          color="#4319ff"
          alignSelf={"flex-start"}
          backgroundColor="#efecff"
          fontSize="14px"
          marginBottom="20px"
          width="fit-content"
        >
          Ult. Act. {lastUpdate}hs
        </Tag>
        : null}
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 2, "2xl": 6 }}
          gap="20px"
          mb="20px"
        >
          <MiniStatistics
            startContent={
              <Flex
                me="-16px"
                mt="0px"
                alignItems="center"
                transition=" opacity 1s ease 0s"
              >
                <FormLabel htmlFor="balance">
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Image
                        src={currency === "USD" ? USA_ICON : ARGENTINA_ICON}
                        borderRadius="full"
                      />
                    }
                  />
                </FormLabel>
              </Flex>
            }
            name="Total Inversiones"
            value={totalInversiones}
            flex="1"
            maxH="100%"
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={<Image src={DOLAR_BLUE} />}
              />
            }
            name="Valor Dolar Blue"
            value={`$${
              dolarBlueValue !== null
                ? dolarBlueValue.toFixed(2)
                : "Cargando..."
            }`}
          />
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
          gap="20px"
          mb="20px"
        >
          <Link to="/admin/gestion-activos?activo=criptomonedas">
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={<Image src={TIPO_ICONOS[1]} />}
                />
              }
              name="Criptomonedas"
              value={criptoValue}
            />
          </Link>
          <Link to="/admin/gestion-activos?activo=cedears">
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={<Image src={TIPO_ICONOS[2]} />}
              />
            }
            name="Cedears"
            value={mervalValue}
          />
          </Link>
          <Link to="/admin/gestion-activos?activo=accionesargentinas">

          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={<Image src={TIPO_ICONOS[3]} />}
              />
            }
            name="Acciones"
            value={accionesValue}
          />
          </Link>
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2, xl: 1 }} gap="20px" mb="20px">
          <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="0px">
            {porcentCrypto !== Infinity && porcentMerval !== Infinity ? (
              <PieCard
                porcentCrypto={porcentCrypto}
                porcentMerval={porcentMerval}
                porcentAcciones={porcentAcciones}
                pieChartData={pieChartData}
              />
            ) : null}

            {Object.keys(userActives).length > 0 ? 
              <>
                  <Card p="20px" align="center" direction="column" w="100%" >
                <Flex
                  px={{ base: "0px", "2xl": "10px" }}
                  justifyContent="space-between"
                  alignItems="center"
                  w="100%"
                  mb="8px"
                >
                  <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
                    Activos totales
                  </Text>
                </Flex>
                <Flex width="100%" justify="space-between" align="flex-start" gap="40px">
                <Flex width="50%" >
                <DoughnutChart data={userActives} actives={userActivesData} /> 
                </Flex>
                <Flex width="50%" direction="column">
                  {userActivesData ?
                    userActivesData.map(item => {
                      return (
                        <>
                          <Flex justify="space-between" align="center" borderBottom="1px solid" borderBottomColor="#eaeaea" padding="4px 0">
                            <Flex align="center" gap="10px">
                              <Box width="8px" height="8px" backgroundColor={item.color} borderRadius="100px"></Box>
                              <Text color="secondaryGray.900" fontWeight="500" fontSize="xs">{item.ticker}</Text>
                            </Flex>
                            <Text color="secondaryGray.900" fontWeight="700" fontSize="xs">{item.porcentaje.toFixed(2)}%</Text>
                          </Flex>
                        </>
                      )
                    })
                  : null}
                </Flex>
                </Flex>
                </Card>
              </>
            : null}
          </SimpleGrid>
        </SimpleGrid>
      </Box>
    </>
  );
};

export default UserReports;
