import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Select,
  Stack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  // extendTheme,
  useColorModeValue,
  ChakraProvider,
  Icon,
  Spinner,
  useToast,
  Box,
  Flex,
  Avatar,
  Text,
  Image, // Importa el ícono de CheckCircle
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { MdQueryStats, Md123 } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import axios from "axios";
import DEFAULT_ICON from "../../../../assets/img/default.png";
import URL from "constants/url";
import { useCurrency } from "contexts/CurrencyContext";
import { TIPO_ICONOS } from "constants/iconos";

// const theme = extendTheme({
//   colors: {
//     brand: {
//       500: "#4319FF",
//       600: "#82FFC9",
//     },
//   },
// });

function ModalBorrarActivo({ isOpen, onClose, ticker, refreshData }) {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  console.log(ticker);

  const { currency } = useCurrency();
  const tipoValor = currency === "ARS" ? "valor_pesos" : "valor";
  const formatter = new Intl.NumberFormat("es-ES", {});

  const handleClose = () => {
    onClose();
  };

  const handleActualizarActivo = async () => {
    setIsLoading(true);
    const update = await axios.delete(
      `${URL}/api/actives/${ticker.User_Active.ActiveId}/${ticker.User_Active.UserId}`
    );
    if (update.status === 201) {
      setTimeout(() => {
        setIsLoading(false);

        toast({
          position: "bottom",
          duration: 3000,
          isClosable: true,
          render: () => (
            <Box
              color="white"
              p={3}
              bg="#38B6FF"
              borderRadius="md"
              fontWeight="500"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              animate={{ opacity: [0, 1], translateY: [10, 0] }}
            >
              ¡Activo eliminado de tu cartera!
              <Icon
                as={CheckCircleIcon}
                boxSize={5}
                motion={{ type: "spring", stiffness: 400, damping: 25 }}
              />
            </Box>
          ),
        });

        refreshData();
        handleClose();
      }, 2000); // Ejem
    }
  };
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");

  return (
    <ChakraProvider>
      <Modal onClose={handleClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="20px">
          <ModalHeader>
            ¿Estás seguro de eliminar de tu cartera el activo {ticker.ticker}?
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody paddingTop="0" paddingBottom="0">
            {/* <Flex direction="column" gap="20px" background="#f6f6f6" borderRadius="20px" padding="10px" >
                <Flex align="center">
                <Avatar
                    src={ticker.logo ? ticker.logo : DEFAULT_ICON}
                    w="40px"
                    h="40px"
                    me="8px"
                    backgroundColor="white"
                />
                <Flex direction="column">
                    <Text color={textColor} fontSize="sm" fontWeight="600">
                    {ticker.ticker}
                    </Text>
                    <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                    {ticker.nombre}
                    </Text>
                </Flex>
                </Flex>
            </Flex> */}
            <Flex direction="column" gap="6px">
              <Flex
                direction="row"
                gap="20px"
                background="#f6f6f6"
                borderRadius="20px"
                padding="10px"
                justifyContent="space-between"
              >
                <Flex align="center">
                <Box position="relative">
                      <Image
                        src={ticker.logo ? ticker.logo : DEFAULT_ICON}
                        w={"40px"}
                        h={"40px"}
                        me="8px"
                        backgroundColor="white"
                        borderWidth="1px"
                        borderColor="#eaeaea"
                        borderRadius="full"
                      />
                      <Image
                        src={TIPO_ICONOS[ticker.id_tipo]}
                        position="absolute"
                        w="15px"
                        h="15px"
                        top="0"
                        right="5px"
                        borderRadius="full"
                      />
                    </Box>
                  <Flex direction="column">
                    <Text color={textColor} fontSize="sm" fontWeight="600">
                      {ticker.ticker}
                    </Text>
                    <Text
                      color={textColorSecondary}
                      fontSize="sm"
                      fontWeight="400"
                    >
                      {ticker.nombre}
                    </Text>
                  </Flex>
                </Flex>
                <Flex direction="column">
                  <Text fontSize="sm" textAlign="right">
                    Cantidad: {ticker.User_Active.amount}
                  </Text>
                  <Text fontSize="sm">
                    Total:
                    {currency === "ARS"
                      ? `$ ${formatter.format(
                          ticker[tipoValor] * ticker.User_Active.amount
                        )}`
                      : `US$ ${formatter.format(
                          (
                            ticker[tipoValor] * ticker.User_Active.amount
                          ).toFixed(2)
                        )}`}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex gap="10px" width="100%">
              <Button
                onClick={handleClose}
                variant="outline"
                style={{
                  color: "#4319FF",
                  width: "50%",
                  fontSize: "14px",
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleActualizarActivo}
                isDisabled={isLoading}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  width: "50%",
                  fontSize: "14px",
                }}
              >
                {isLoading ? (
                  <Spinner size="sm" color="white" />
                ) : (
                  "Eliminar Activo"
                )}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default ModalBorrarActivo;
