export const agregarActivoReglas = (state, ticker) => {

    return {
        tickerId: [
            {
                regla: state.tickerId === "",
                mensaje: 'El ticker es requerido.'
            }
        ],
        amount: [
            {
                regla: state.amount === "" || Number(state.amount) === 0,
                mensaje: 'La cantidad es requerida.'
            },
            {
                regla: Number(state.amount) < 0 ,
                mensaje: 'Cantidad no puede ser negativo.'
            },
            {
                regla: (ticker?.ticker?.id_tipo === 2 || ticker?.ticker?.id_tipo === 3) && !Number.isInteger(Number(state.amount)),
                mensaje: 'Cantidad tiene que ser un número entero.'
            }
        ]
    }
};

export const editarActivoReglas = (state, ticker) => {

    return {
        amount: [
            {
                regla: state.amount === "" || Number(state.amount) === 0,
                mensaje: 'La cantidad es requerida.'
            },
            {
                regla: Number(state.amount) < 0 ,
                mensaje: 'Cantidad no puede ser negativo.'
            },
            {
                regla: (ticker?.ticker?.id_tipo === 2 || ticker?.ticker?.id_tipo === 3) && !Number.isInteger(Number(state.amount)),
                mensaje: 'Cantidad tiene que ser un número entero.'
            }
        ]
    }
};