import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Select,
  Stack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  // extendTheme,
  useColorModeValue,
  ChakraProvider,
  Icon,
  Spinner,
  useToast,
  Box,
  Flex,
  Avatar,
  Text,
  Badge,
  FormLabel,
  Image, // Importa el ícono de CheckCircle
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { MdQueryStats, Md123 } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import axios from "axios";
import DEFAULT_ICON from "../../../../assets/img/default.png";
import URL from "constants/url";
import { useCurrency } from "contexts/CurrencyContext";
import { editarActivoReglas } from "utils/formRules";
import { editarActivoInitialValues } from "utils/formInitialValues";
import { useForm } from "../../../../hooks/useForm";
import { TIPO_ICONOS } from "constants/iconos";

// const theme = extendTheme({
//   colors: {
//     brand: {
//       500: "#4319FF",
//       600: "#82FFC9",
//     },
//   },
// });

function ModalEditarActivo({ isOpen, onClose, ticker, refreshData, amount }) {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const { currency } = useCurrency();
  const tipoValor = currency === "ARS" ? "valor_pesos" : "valor";
  const formatter = new Intl.NumberFormat("es-ES", {});

  const {
    inputValues,
    handleChange,
    formErrors,
    resetFormErrors,
    handleValidation,
    resetForm,
  } = useForm(handleSubmit, editarActivoReglas, amount);

  async function handleSubmit() {
    setIsLoading(true);
    const update = await axios.put(
      `${URL}/api/actives/edit/${ticker.User_Active.ActiveId}/${ticker.User_Active.UserId}`,
      inputValues
    );
    if (update.status === 201) {
      setTimeout(() => {
        setIsLoading(false);

        toast({
          position: "bottom",
          duration: 3000,
          isClosable: true,
          render: () => (
            <Box
              color="white"
              p={3}
              bg="#38B6FF"
              borderRadius="md"
              fontWeight="500"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              animate={{ opacity: [0, 1], translateY: [10, 0] }}
            >
              ¡Activo actualizado!
              <Icon
                as={CheckCircleIcon}
                boxSize={5}
                motion={{ type: "spring", stiffness: 400, damping: 25 }}
              />
            </Box>
          ),
        });

        refreshData();
        handleClose();
      }, 2000); // Ejem
    }
  }

  const handleClose = () => {
    onClose();
  };

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");

  return (
    <ChakraProvider>
      <Modal onClose={handleClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="20px">
          <ModalHeader>Editar Activo</ModalHeader>
          <ModalCloseButton />
          <ModalBody paddingTop="0" paddingBottom="0">
            <Flex direction="column" gap="20px">
              <Flex direction="column" gap="6px">
                <Text fontSize="sm" fontWeight="500">
                  En tu cartera:
                </Text>
                <Flex
                  direction="row"
                  gap="20px"
                  background="#f6f6f6"
                  borderRadius="20px"
                  padding="10px"
                  justifyContent="space-between"
                >
                  <Flex align="center">
                    <Box position="relative">
                      <Image
                        src={ticker.logo ? ticker.logo : DEFAULT_ICON}
                        w={"40px"}
                        h={"40px"}
                        me="8px"
                        backgroundColor="white"
                        borderWidth="1px"
                        borderColor="#eaeaea"
                        borderRadius="full"
                      />
                      <Image
                        src={TIPO_ICONOS[ticker.id_tipo]}
                        position="absolute"
                        w="15px"
                        h="15px"
                        top="0"
                        right="5px"
                        borderRadius="full"
                      />
                    </Box>
                    <Flex direction="column">
                      <Text color={textColor} fontSize="sm" fontWeight="600">
                        {ticker.ticker}
                      </Text>
                      <Text
                        color={textColorSecondary}
                        fontSize="sm"
                        fontWeight="400"
                      >
                        {ticker.nombre}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex direction="column">
                    <Text fontSize="sm" textAlign="right">
                      <Badge>
                        Total:{" "}
                        {currency === "ARS"
                          ? `$ ${formatter.format(
                              ticker[tipoValor] * ticker.User_Active.amount
                            )}`
                          : `US$ ${formatter.format(
                              (
                                ticker[tipoValor] * ticker.User_Active.amount
                              ).toFixed(2)
                            )}`}
                      </Badge>
                    </Text>
                    <Text fontSize="sm" textAlign="right">
                      Cantidad: {ticker.User_Active.amount}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Stack spacing={4}>
                <Flex direction="column" position="relative">
                  <FormLabel
                    display="flex"
                    //ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={useColorModeValue("navy.700", "white")}
                    //mb="8px"
                  >
                    Cantidad
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement fontSize="30px" height="100%">
                      <Md123 />
                    </InputLeftElement>
                    <Input
                      name="amount"
                      type="number"
                      placeholder="Cantidad"
                      value={inputValues.amount}
                      onChange={handleChange}
                      fontSize="sm"
                      fontWeight="500"
                      size="lg"
                      mt="0px"
                      focusBorderColor={useColorModeValue("#422AFB", "white")}
                    />
                  </InputGroup>
                  {formErrors.amount ? (
                    <Box>
                      <Text fontSize="14px" color="red.500">
                        {formErrors.amount}
                      </Text>
                    </Box>
                  ) : null}
                </Flex>
              </Stack>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex gap="10px" width="100%">
              <Button
                onClick={handleClose}
                variant="outline"
                style={{
                  color: "#4319FF",
                  width: "50%",
                  fontSize: "14px",
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleValidation}
                isDisabled={isLoading}
                style={{
                  backgroundColor: "#4319FF",
                  color: "white",
                  width: "50%",
                  fontSize: "14px",
                }}
              >
                {isLoading ? (
                  <Spinner size="sm" color="white" />
                ) : (
                  "Actualizar Activo"
                )}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default ModalEditarActivo;
