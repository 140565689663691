import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Text } from "@chakra-ui/react";
import Banner from "views/admin/profile/components/Banner";
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import { useHistory } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const Overview = () => {
  const [user, setUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      history.push("/auth/iniciar-sesion");
    } else {
      const decodedToken = jwtDecode(token);

      setUser(decodedToken);
      if (decodedToken.exp * 1000 < Date.now()) {
        history.push("/auth/iniciar-sesion");
      }
    }
  }, [history]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px", sm: "100px" }} mt='30px'>
      {/* Main Fields */}

        <Banner
          gridArea="1 / 1 / 2 / 2"
          banner={banner}
          avatar={avatar}
          name={user ? `ID# ${user.userId}` : ""}
          job={
            user ? (
              <Text>
                <Text fontWeight="bold">Email:</Text>{" "}
                {user.userEmail}
              </Text>
            ) : (
              ""
            )
          }
        />
    </Box>
  );
};

export default Overview;
