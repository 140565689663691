import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

// Chakra imports
import {
  Box,
  Flex,
  Grid,
  SimpleGrid,
  Tag,
  useMediaQuery,
} from "@chakra-ui/react";

// Custom components
import ActivesTable from "./components/ActivesTable";
import Card from "components/card/Card.js";
import { tableActivesColumns } from "./variables/tableActives";
import axios from "axios";
import URL from "constants/url";

// Assets

export default function Activos() {
  const history = useHistory();
  const [decodedToken, setDecodedToken] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [lastUpdate, setLastUpdate] = useState(null);

  const TIPOS = {
    1: "Criptomonedas",
    2: "Cedears",
    3: "Acciones Argentinas",
  };

  // Chakra Color Mode
  const textColor = "#1B2559";
  const textColorBrand = "#422AFB";

  const handleType = (type) => setSelectedType(type);

  const getLastUpdate = async () => {
    try {
      const lastUpdate = await axios.get(`${URL}/api/actives/last_update`);
      console.log("AST UPDASDIJAOIDJIOSAJDOIASJDIOSAJOISJDAIOAJOIDAJOIJAOISDJOIAJOISD")
      console.log(lastUpdate.data)
      setLastUpdate(lastUpdate.data.lastUpdate);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLastUpdate();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      history.push("/auth/iniciar-sesion");
    } else {
      (async () => {
        setDecodedToken(await jwtDecode(token));
      })();

      if (decodedToken && decodedToken?.exp * 1000 < Date.now()) {
        history.push("/auth/iniciar-sesion");
      }
    }
  }, [history]);

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px", sm: "80px" }}>
      {/* Main Fields */}
      <Grid
        mb="20px"
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block" }}
      >
        <Flex
          flexDirection="column"
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
        >
          {lastUpdate ? (
            <Tag
              variant="subtle"
              color="#4319ff"
              alignSelf={"flex-start"}
              backgroundColor="#efecff"
              fontSize="14px"
              marginTop="40px"
              width="fit-content"
            >
              Ult. Act. {lastUpdate}hs
            </Tag>
          ) : null}
          {/* <Card px="0px" marginTop="30px"> */}
          {decodedToken &&
            (!selectedType ? (
              <Grid
                templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(3, 1fr)"}
                gap="5"
              >
                <Card px="0px" marginTop="30px">
                  <ActivesTable
                    columnsData={tableActivesColumns}
                    decodedToken={decodedToken}
                    tipo={1}
                    nombreTipo="Criptomonedas"
                    handleType={handleType}
                    selectedType={selectedType}
                  />
                </Card>
                <Card px="0px" marginTop="30px">
                  <ActivesTable
                    columnsData={tableActivesColumns}
                    decodedToken={decodedToken}
                    tipo={2}
                    nombreTipo="Cedears"
                    handleType={handleType}
                    selectedType={selectedType}
                  />
                </Card>
                <Card px="0px" marginTop="30px">
                  <ActivesTable
                    columnsData={tableActivesColumns}
                    decodedToken={decodedToken}
                    tipo={3}
                    nombreTipo="Acciones Argentinas"
                    handleType={handleType}
                    selectedType={selectedType}
                  />
                </Card>
              </Grid>
            ) : (
              <Grid templateColumns="repeat(1, 1fr)" gap="5">
                <Card px="0px" marginTop="30px">
                  <ActivesTable
                    columnsData={tableActivesColumns}
                    decodedToken={decodedToken}
                    tipo={selectedType}
                    nombreTipo={TIPOS[selectedType]}
                    handleType={handleType}
                    selectedType={selectedType}
                  />
                </Card>
              </Grid>
            ))}
          {/* </Card> */}
        </Flex>
      </Grid>
      {/* Delete Product */}
    </Box>
  );
}
