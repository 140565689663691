import React, { useContext, useState } from 'react'

const CurrencyContext = React.createContext()

export function useCurrency() {
  return useContext(CurrencyContext)
}

export function CurrencyProvider({ children }) {
  const currentCurrency = localStorage.getItem("currency")
  console.log("CURRENT CURRENCY")
  console.log(currentCurrency)
  const [currency, setCurrency] = useState(currentCurrency ? currentCurrency : 'ARS');

  const handleChangeCurrency = () => {
    const currentCurrency = currency === "ARS" ? "USD" : "ARS"
    localStorage.setItem("currency", currentCurrency)
    setCurrency(currentCurrency)
  }
  
  return (
    <CurrencyContext.Provider value={{ currency, handleChangeCurrency }}>
        {children}
    </CurrencyContext.Provider>
  )
}