import React, { useEffect, useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  Heading,
  Icon,
  IconButton,
  Image,
  Link,
  Tag,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import {
  MdModeEdit,
  MdDeblur,
  MdDelete,
  MdArrowRightAlt,
  MdChevronRight,
  MdChevronLeft,
  MdArrowDropUp,
  MdArrowDropDown,
  MdArrowDownward,
  MdArrowUpward,
  MdArrowBack,
} from "react-icons/md";
import DEFAULT_ICON from "../../../../assets/img/default.png";
import { FaCoins } from "react-icons/fa";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import URL from "constants/url";
import { useCurrency } from "contexts/CurrencyContext";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { TIPO_ICONOS } from "constants/iconos";
import { HSeparator } from "components/separator/Separator";
import dayjs from "dayjs";

function UsuariosTable() {
  const tableActivesColumns = [
    {
      header: "ID",
      accessorFn: (row) => row.id,
    },
    {
      header: "Email",
      accessorFn: (row) => row.email,
    },
    {
      header: "Rol",
      cell: ({ cell }) => {
        return (
          <Text>
            {cell.row.original.role === 1 ? "Admin" : "Usuario"}
          </Text>
        );
      },
      accessorFn: (row) => row.status,
    },
    {
      header: "Fecha registro",
      cell: ({ cell }) => {
        return (
          <Text>
            {dayjs(cell.row.original.createdAt).format("DD/MM/YYYY HH:mm")}hs
          </Text>
        );
      },
      accessorFn: (row) => row.last_connection,
    },
    {
      header: "Tipo",
      cell: ({ cell }) => {
        return (
          <Text>
            {cell.row.original.contraseña ? "Credenciales" : "Google"}
          </Text>
        );
      },
      accessorFn: (row) => row.last_connection,
    },
    {
      header: "Verificado",
      cell: ({ cell }) => {
        console.log(cell.row.original.status)
        return (
          <Text>
            {cell.row.original.status ? "Verificado" : "-"}
          </Text>
        );
      },
      accessorFn: (row) => row.status,
    },
    {
      header: "Últ. Conexión",
      cell: ({ cell }) => {
        return (
          <Text>
            {cell.row.original.last_connection ? `${dayjs(cell.row.original.last_connection).format("DD/MM/YYYY HH:mm")}hs` : '-'}
          </Text>
        );
      },
      accessorFn: (row) => row.last_connection,
    },
  ];

  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const columns = tableActivesColumns
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState([])

  const getUsuarios = async () => {
    setLoading(true)
    try {
        const usuarios = await axios.get(`${URL}/api/users`);
        setUsuarios(usuarios.data)
        console.log(usuarios.data)
        setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  useEffect(() => {
    getUsuarios();
  }, []);


  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });
  const [sorting, setSorting] = useState([]);

  const tableInstance = useReactTable({
    columns,
    data: usuarios,
    state: {
      pagination,
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
  });

  const textColor = "#1B254B";
  const textColorSecondary = "#A3AED0";

  return (
    <>
      <Box
        maxWidth="100%"
        overflowX="hidden"
        overflowY={isMobile ? "hidden" : "auto"}
        pb={isMobile ? "20px" : 0}
        // padding={"0px 20px"}
      >
        {loading ? (
          <Flex
            width="100%"
            justifyContent="center"
            direction="column"
            alignItems="center"
            gap="16px"
            padding="40px"
          >
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap="2px"
            >
              <Heading size="md">Cargando usuarios...</Heading>
            </Flex>
          </Flex>
        ) : null}
        {usuarios.length > 0 ? (
          <>

            <Table variant="simple" color="gray.500">
              {!isMobile ?
              <Thead>
                {tableInstance.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      console.log(header)
                      return (
                      <Th
                        key={header.id}
                        pe="10px"
                        cursor={header.column.getCanSort() ? "pointer" : ""}
                        borderColor="transparent"
                        p={isMobile ? "8px" : ""}
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Th>
                      )
                      })}
                  </Tr>
                ))}
              </Thead>
              : null}
              <Tbody>
                {tableInstance.getRowModel().rows.map((row) => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "80px", md: "120px", lg: "auto" }}
                        borderColor="transparent"
                        p={isMobile ? "8px" : ""}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </>
        ) : null}
        {usuarios.length === 0 && !loading ? (
          <Flex
            width="100%"
            justifyContent="center"
            direction="column"
            alignItems="center"
            gap="16px"
            padding="40px"
          >
            <Icon fontSize="2xl" color="#4319ff">
              <FaCoins />
            </Icon>
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap="2px"
            >
              <Heading size="md">No se encontraron usuarios</Heading>
              <Text>
                No hay usuarios registrados
              </Text>
            </Flex>
          </Flex>
        ) : null}
      </Box>
    </>
  );
}

export default UsuariosTable;
