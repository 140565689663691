import { useState } from "react"

export default function useValidation (){
    const [formErrors, setFormErrors] = useState({})

    const validateItem = (rules, key, errorsData) => {
        rules[key].forEach(item => {
            if(!errorsData.hasOwnProperty(key) && item.regla){
                errorsData[key] = item.mensaje
            }    
            // Chequear que no haya errores en todas las validaciones del input para borrar el mensaje de error
            const isError = rules[key].some(item => item.regla === true)
            if(!isError){
                const errorsForm = { ...errorsData }
                delete errorsForm[key]
            }
        })
    }

    const validateForm = (rules) => {
        let errorsData = {}
        for (const key in rules) {
            if(Array.isArray(rules[key])){
                validateItem(rules, key, errorsData)
            } else {
                for (const item in rules[key]) {
                    validateItem(rules[key], item, errorsData)
                }
            }
        }
        setFormErrors(errorsData)
        return errorsData
    }

    const resetFormErrors = () => setFormErrors({})

    return { 
        validateForm, 
        formErrors,
        resetFormErrors
    }
}