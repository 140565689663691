// TickerComponent.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import Ticker from "react-ticker";
import { Avatar, Box, Flex, Icon, Image, keyframes, Text } from "@chakra-ui/react";
import icons from "./iconsReference"; // Asegúrate de ajustar la ruta según tu estructura de archivos
import DefaultIcon from "../../assets/img/default.png";
import URL from "constants/url";
import { useCurrency } from "contexts/CurrencyContext";
import Marquee from "react-fast-marquee";
import { TIPO_ICONOS } from "constants/iconos";
import { MdArrowDownward, MdArrowDropDown, MdArrowDropUp, MdArrowUpward } from "react-icons/md";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";

const spin = keyframes`  
  from {transform: translateX(0);}   
  to {transform: translateX(-100%)} 
`;

const TickerComponent = () => {
  const [allAssets, setAllAssets] = useState([]);
  const [focused, setFocused] = useState(true);
  const { currency } = useCurrency();
  const tipoValor = currency === "ARS" ? "valor_pesos" : "valor";
  const tipoValorAnterior = currency === "ARS" ? "valor_anterior_pesos" : "valor_anterior";
  const formatter = new Intl.NumberFormat("es-ES", {});
  //const formatter = new Intl.NumberFormat('decimal', { maximumSignificantDigits: 2 })


  const spinAnimation = `${spin} infinite 25s linear`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const actives = await axios.get(`${URL}/api/actives/variations`);
        // console.log(actives.data)
        // const response = await axios.get(`${URL}/api/actives`);
        setAllAssets(actives.data);
        //setAllAssets(response.data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currency]);

  useEffect(() => {
    const handleFocus = () => {
      setFocused(true);
    };

    const handleBlur = () => {
      setFocused(false);
    };

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  if (!allAssets || allAssets.length === 0) {
    return (
      <div style={{ marginBottom: "20px" }}>No hay activos para mostrar.</div>
    );
  }

  return (
    // <Box

    //   position="fixed"
    //   top="0"
    //   left="0"
    //   right="0"
    //   zIndex="1000"
    //   style={{
    //     background:" linear-gradient(180deg, rgba(66,42,251,1) 75%, rgba(131,255,201,0.5887605042016807) 100%)",
    //     padding: "10px",
    //     borderRadius: "0px",
    //     fontFamily: "DM Sans, sans-serif",
    //     fontSize: "14px",
    //   }}
    // >
    //   <Ticker speed={5} direction="toLeft" move={focused}>
    //     {({ index }) => {
    //       const currentAsset = allAssets[index % allAssets.length];
    //       const avatar = (
    //         <Avatar
    //           backgroundColor="white"
    //           src={
    //             currentAsset.logo
    //               ? currentAsset.logo
    //               : DefaultIcon
    //           }
    //           alt={currentAsset.ticker}
    //           w="20px"
    //           h="20px"
    //           borderRadius="50%"
    //           marginRight="8px"
    //         />
    //       );

    //       return (
    //         <Box
    //           display="flex"
    //           alignItems="center"
    //           padding="0px"
    //           borderRadius="15px"
    //           fontFamily="DM Sans, sans-serif"
    //           fontSize="12px"
    //           marginBottom="0px"
    //           marginTop="0px"
    //         >
    //           {avatar}
    //           <Text color="#FFFFFF" fontWeight="bold">
    //             {currentAsset.ticker}
    //             {/* {tipoValor} */}
    //           </Text>
    //           <span style={{ color: "#FFFFFF", marginLeft: "8px" }}>
    //           {currency === "ARS" ? `$ ${formatter.format(currentAsset[tipoValor])}` : `US$ ${formatter.format((currentAsset[tipoValor]).toFixed(2))}`}
    //           </span>
    //           {"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0|\u00A0\u00A0"}
    //         </Box>
    //       );
    //     }}
    //   </Ticker>
    // </Box>
    <>
      <Box
        position="fixed"
        top="0"
        left="0"
        right="0"
        zIndex="1000"
        style={{
          background:
            " linear-gradient(180deg, rgba(66,42,251,1) 75%, rgba(131,255,201,0.5887605042016807) 100%)",
          padding: "10px",
          borderRadius: "0px",
          fontFamily: "DM Sans, sans-serif",
          fontSize: "14px",
        }}
      >
        <Box display="flex" flexDirection="row" gap="20px">
          <Marquee style={{ display: "flex", gap: "20px" }}>
            {allAssets.length > 0
              ? allAssets.map((item) => {
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      padding="0px"
                      borderRadius="15px"
                      fontFamily="DM Sans, sans-serif"
                      fontSize="12px"
                      marginBottom="0px"
                      marginTop="0px"
                    >
                      <Box position="relative">
                        <Image
                          src={item.logo ? item.logo : DefaultIcon}
                          alt={item.ticker}
                          w="20px"
                          h="20px"
                          marginRight="6px"
                          marginLeft="12px"
                          borderRadius="full"
                          position={"relative"}
                        />
                        <Image
                          src={TIPO_ICONOS[item.id_tipo]}
                          position="absolute"
                          w="10px"
                          h="10px"
                          top="0"
                          right="1px"
                          borderRadius="full"
                          zIndex="2"
                        />
                      </Box>
                      {/* <Avatar
              backgroundColor="white"
              src={
                item.logo
                  ? item.logo
                  : DefaultIcon
              }
              alt={item.ticker}
              w="20px"
              h="20px"
              borderRadius="50%"
              marginRight="6px"
              marginLeft="12px"
            /> */}
                      <Text color="#FFFFFF" fontWeight="bold" marginLeft="4px">
                        {item.ticker}
                        {/* {tipoValor} */}
                      </Text>
                      <Flex align={"center"}>
                        <Text
                          color="#fff"
                          whiteSpace="nowrap"
                          marginRight="1px"
                          marginLeft="6px"
                        >
                          {currency === "ARS"
                            ? `$ ${formatter.format(item[tipoValor])}`
                            : `US$ ${formatter.format(
                                item[tipoValor]
                              )}`}
                        </Text>
                        <Icon color={parseFloat(item.variacion_porcentaje) < 0 ? "#ff4d57" : "#21f0a2"} fontSize={"22px"} position={"relative"} display="flex" alignItems="center">{parseFloat(item.variacion_porcentaje) < 0 ? <BsArrowDownShort /> : <BsArrowUpShort />}</Icon>
                        <Text
                          color={parseFloat(item.variacion_porcentaje) < 0 ? "#ff4d57" : "#21f0a2"}
                          whiteSpace="nowrap"
                          marginRight="1px"
                        >
                          {currency === "ARS"
                            ? `$ ${formatter.format(item[tipoValor] - item[tipoValorAnterior])}`
                            : `US$ ${formatter.format(
                                item[tipoValor] - item[tipoValorAnterior]
                              )}`} ({item.variacion_porcentaje.toFixed(2)}%)
                        </Text>
                      </Flex>
                    </Box>
                  );
                })
              : null}
            {allAssets.length > 0
              ? allAssets.map((item) => {
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    padding="0px"
                    borderRadius="15px"
                    fontFamily="DM Sans, sans-serif"
                    fontSize="12px"
                    marginBottom="0px"
                    marginTop="0px"
                  >
                    <Box position="relative">
                      <Image
                        src={item.logo ? item.logo : DefaultIcon}
                        alt={item.ticker}
                        w="20px"
                        h="20px"
                        marginRight="6px"
                        marginLeft="12px"
                        borderRadius="full"
                        position={"relative"}
                      />
                      <Image
                        src={TIPO_ICONOS[item.id_tipo]}
                        position="absolute"
                        w="10px"
                        h="10px"
                        top="0"
                        right="1px"
                        borderRadius="full"
                        zIndex="2"
                      />
                    </Box>
                    {/* <Avatar
            backgroundColor="white"
            src={
              item.logo
                ? item.logo
                : DefaultIcon
            }
            alt={item.ticker}
            w="20px"
            h="20px"
            borderRadius="50%"
            marginRight="6px"
            marginLeft="12px"
          /> */}
                    <Text color="#FFFFFF" fontWeight="bold" marginLeft="4px">
                      {item.ticker}
                      {/* {tipoValor} */}
                    </Text>
                    <Flex align={"center"}>
                      <Text
                        color="#fff"
                        whiteSpace="nowrap"
                        marginRight="1px"
                        marginLeft="6px"
                      >
                        {currency === "ARS"
                          ? `$ ${formatter.format(item[tipoValor])}`
                          : `US$ ${formatter.format(
                              item[tipoValor]
                            )}`}
                      </Text>
                      <Icon color={parseFloat(item.variacion_porcentaje) < 0 ? "#ff4d57" : "#21f0a2"} fontSize={"22px"} position={"relative"} display="flex" alignItems="center">{parseFloat(item.variacion_porcentaje) < 0 ? <BsArrowDownShort /> : <BsArrowUpShort />}</Icon>
                      <Text
                          color={parseFloat(item.variacion_porcentaje) < 0 ? "#ff4d57" : "#21f0a2"}
                          whiteSpace="nowrap"
                          marginRight="1px"
                        >
                          {currency === "ARS"
                            ? `$ ${formatter.format(item[tipoValor] - item[tipoValorAnterior])}`
                            : `US$ ${formatter.format(
                                item[tipoValor] - item[tipoValorAnterior]
                              )}`} ({item.variacion_porcentaje.toFixed(2)}%)
                        </Text>
                    </Flex>
                  </Box>
                );
                })
              : null}
          </Marquee>
        </Box>
      </Box>
    </>
  );
};

export default TickerComponent;
