import { jwtDecode } from "jwt-decode";
import { createContext, useState, useContext } from "react";

const AuthContext = createContext();
const { Provider } = AuthContext;

const useAuthContext = () => {
    const wizard = useContext(AuthContext)
    return wizard
}

const AuthProvider = ({children}) => {
    // Setea el valor de userInfo que se encuentra en localStorage
    const storageToken = localStorage.getItem('token')
    const decodedToken = storageToken ? jwtDecode(storageToken) : null

    const [user, setUser] = useState(decodedToken ? decodedToken : null)

    // const [authState, setAuthState] = useState({
    //     token: null,
    //     expiresIn: expiresIn ? expiresIn : null,
    //     userInfo: userInfo ? JSON.parse(userInfo) : ''
    // })

    return (
        <Provider value={{ user, setUser }} >
            { children }
        </Provider>
    )
}

export { AuthContext, AuthProvider, useAuthContext }