import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components
import LogoNuevo from "../../../assets/img/Logo-Horizontal.png"
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {

  return (
    <Flex align='center' direction='column'>
      <img src={LogoNuevo} alt="Nuevo Logo" style={{ width: '305px', marginBottom: "10px" }} /> {/* Usa el nuevo logo */}
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
