import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

// Chakra imports
import {
  Box,
  Flex,
  Grid,
  Heading,
  List,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
import { HSeparator } from "components/separator/Separator";

// Assets

export default function TerminosCondiciones() {
  const history = useHistory();
  const [decodedToken, setDecodedToken] = useState(null);

  // Chakra Color Mode
  const textColor = "#1B2559";
  const textColorBrand = "#422AFB";

  // useEffect(() => {
  //   const token = localStorage.getItem("token");

  //   console.log(token)

  //   if (!token) {
  //     history.push("/auth/iniciar-sesion");
  //   } else {
  //     (async () => {
  //       setDecodedToken(await jwtDecode(token));
  //     })();

  //     if (decodedToken && decodedToken?.exp * 1000 < Date.now()) {
  //       history.push("/auth/iniciar-sesion");
  //     }
  //   }
  // }, [history]);

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        mb="20px"
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block" }}
      >
        <Flex
          flexDirection="column"
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
          gap="16px"
          marginTop="40px"
          padding="20px"
          backgroundColor={"#fff"}
          borderRadius="8px"
        >
          <Text>
            Por favor, lea detenidamente los presentes términos y condiciones de
            uso (en adelante, los "Términos y Condiciones") antes de registrarse
            y utilizar la plataforma Cartera Argenta (en adelante, la
            "Plataforma"). Al registrarse, usted declara haber leído,
            comprendido y aceptado íntegramente estos Términos y Condiciones.
          </Text>
          <HSeparator />
          <Heading size="sm">1. Objeto de la Plataforma</Heading>
          <Text>
            Cartera Argenta es una herramienta diseñada para permitir la
            consulta, registro y visualización de información financiera
            relativa a activos del mercado argentino y criptomonedas, basada en
            datos obtenidos de terceros y fuentes públicas disponibles.
          </Text>
          <UnorderedList>
            <li>
              Carácter Informativo: La información proporcionada en la
              Plataforma tiene carácter meramente informativo y referencial. Los
              valores y estimaciones mostrados no constituyen recomendaciones de
              inversión ni asesoramiento financiero, contable, impositivo o
              legal.
            </li>
            <li>
              Limitación de Responsabilidad: Cartera Argenta no garantiza la
              precisión, exactitud, vigencia o completitud de los datos
              proporcionados. Por lo tanto, la utilización de esta información
              es bajo exclusiva responsabilidad del usuario..
            </li>
          </UnorderedList>

          <HSeparator />

          <Heading size="sm">2. Exclusión de Responsabilidad</Heading>
          <Text>Cartera Argenta no se responsabiliza por:</Text>
          <UnorderedList>
            <li>
              Errores, inexactitudes u omisiones en la información mostrada en
              la Plataforma, incluyendo las derivadas de actualizaciones o
              cambios en los datos proporcionados por terceros.
            </li>
            <li>
              Decisiones o acciones tomadas por los usuarios basadas en la
              información proporcionada por la Plataforma.
            </li>
            <li>
              Daños y perjuicios de cualquier naturaleza, ya sean directos,
              indirectos, emergentes o lucrativos, derivados del uso de la
              Plataforma.
            </li>
          </UnorderedList>
          <Text>
            El usuario reconoce que los valores y análisis proporcionados son
            aproximados y especulativos, pudiendo no reflejar la realidad del
            mercado en tiempo real.
          </Text>

          <HSeparator />

          <Heading size="sm">3. Uso Permitido de la Plataforma</Heading>
          <Text>
            El acceso y uso de la Plataforma se limita exclusivamente a fines
            lícitos y personales. El usuario se compromete a:
          </Text>
          <UnorderedList>
            <li>
              Utilizar la Plataforma de conformidad con la legislación vigente
              en la República Argentina y cualquier otra normativa aplicable.
            </li>
            <li>
              Abstenerse de utilizar la Plataforma para actividades ilícitas,
              incluyendo, pero no limitado a, fraude financiero, lavado de
              activos u otras conductas prohibidas por la ley.
            </li>
            <li>
              Proveer información veraz y completa al momento del registro y
              durante el uso de la Plataforma.
            </li>
          </UnorderedList>
          <Text>
            Cartera Argenta se reserva el derecho de restringir o cancelar el
            acceso de cualquier usuario que incumpla con estos Términos y
            Condiciones, sin previo aviso.
          </Text>

          <HSeparator />

          <Heading size="sm">4. Políticas de Privacidad</Heading>
          <Text>
            Cartera Argenta recolecta y procesa datos personales del usuario en
            conformidad con la Ley N° 25.326 (Ley de Protección de los Datos
            Personales) y demás normativas aplicables en la República Argentina.
          </Text>
          <UnorderedList>
            <li>
              Datos Recolectados: Incluyen, entre otros, nombre, correo
              electrónico e información de inicio de sesión.
            </li>
            <li>
              Uso de los Datos: Los datos se utilizarán exclusivamente para el
              funcionamiento de la Plataforma y no serán compartidos con
              terceros, salvo autorización expresa del usuario o requerimiento
              legal.
            </li>
            <li>
              Seguridad: La Plataforma implementa medidas de seguridad
              razonables para proteger los datos personales de los usuarios,
              aunque no puede garantizar una seguridad absoluta contra
              vulneraciones.
            </li>
          </UnorderedList>

          <HSeparator />

          <Heading size="sm">5. Limitación de Responsabilidad Técnica</Heading>
          <Text>
            Cartera Argenta no será responsable por interrupciones en el
            servicio, demoras o errores derivados de problemas técnicos,
            mantenimiento, actualizaciones o cualquier otra causa fuera de su
            control razonable.
          </Text>
          <Text>
            Asimismo, la Plataforma no garantiza disponibilidad continua ni
            libre de fallas, ni se responsabiliza por posibles daños a los
            dispositivos o conexiones de los usuarios resultantes del uso de la
            misma.
          </Text>

          <HSeparator />

          <Heading size="sm">
            6. Modificaciones a los Términos y Condiciones
          </Heading>
          <Text>
            Cartera Argenta se reserva el derecho de modificar, actualizar o
            eliminar parcial o totalmente los presentes Términos y Condiciones
            en cualquier momento. Dichas modificaciones serán notificadas
            mediante el correo electrónico registrado o al ingresar nuevamente
            en la Plataforma, debiendo el usuario aceptar los términos
            actualizados para continuar utilizando el servicio.
          </Text>

          <HSeparator />

          <Heading size="sm">7. Jurisdicción y Ley Aplicable</Heading>
          <Text>
            Los presentes Términos y Condiciones se rigen por las leyes de la
            República Argentina. Cualquier controversia derivada del uso de la
            Plataforma será sometida a la jurisdicción exclusiva de los
            tribunales ordinarios de la Ciudad Autónoma de Buenos Aires.
          </Text>

          <HSeparator />
          <Heading size="sm">Aceptación y Consentimiento</Heading>
          <Text>
          Al registrarse, el usuario declara expresamente haber leído, comprendido y aceptado íntegramente los presentes Términos y Condiciones. Asimismo, acepta que los valores y datos mostrados son aproximados y que Cartera Argenta no se hace responsable por las decisiones que el usuario tome basándose en dicha información.
          </Text>

          <HSeparator />
          <Text>
          Si tiene preguntas, puede contactarnos en: info@cartera-argenta.com
          </Text>
          <Text>
          Fecha de actualización: 18/11/2024
          </Text>
        </Flex>
      </Grid>
      {/* Delete Product */}
    </Box>
  );
}
